import React from 'react';

const AlertModal = ({isOpen, message, onOk}) => {

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" onClick={onkeydown}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <p>{message}</p>
          <div className="modal-buttons">
              <button onClick={onOk} className='confirm'>OK</button>
          </div>
        </div>
      </div>
    );
};

export default AlertModal;