import React, { useEffect, useRef } from 'react';
import { FaArrowLeft } from "react-icons/fa6";


const BookPreview = ({ embeddable, loaded, bookId, setTab }) => {

    const viewerRef = useRef(null);

    useEffect(() => {
        if (loaded) {
            if (viewerRef.current !== null) {
                const viewer = new window.google.books.DefaultViewer(viewerRef.current);
                viewer.load(bookId);
            }
        }
    }, [loaded, bookId]);

    return (
        <>
        <div style={{width:'100%', display:'flex', justifyContent:'flex-start', marginBottom:'10px'}}>    
            <p className='hover-to-green' style={{fontSize:'18px', color:'#999999', fontWeight:'500'}} onClick={() => setTab('Overview')}>Close preview</p>
        </div>
        <div style={{position:'relative', overflow:'hidden', width:'100%'}}>
            {embeddable ? (
                loaded ? (
                    <div ref={viewerRef} id='googleBookViewer'/>
                ) : (
                    <div style={{width:'100%', height:'100px', display:'flex', alignItems:'center', justifyContent:'center', paddingLeft:'12px'}}>
                        Loading preview...
                    </div>
                )
            ) : (
                <div style={{width:'100%', height:'100px', display:'flex', alignItems:'center', justifyContent:'center', paddingLeft:'12px'}}>
                    Preview not available
                </div>
            )}
        </div>
        </>
    );
};

export default BookPreview;