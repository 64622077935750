import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { subjectPage } from '@linko/shared_utils'
import CustomLink from '../../Components/CustomLink';

const CustomTagRel = ({relatedSubjects}) => {
    const [showAllSubjects, setShowAllSubjects] = useState(false);

    if (relatedSubjects.length === 0) {
        return null;
    }

    return (
        <div className='subject-rel'>
            <div className='subject-rel-title'>Related Subjects</div>
            <div className='subject-rel-list'>
                {relatedSubjects
                    .sort((a, b) => {
                        if (a.is_linked !== b.is_linked) {
                            return a.is_linked ? -1 : 1;
                        }
                        return b.learn_count - a.learn_count;
                    })
                    .slice(0, showAllSubjects ? relatedSubjects.length : 5)
                    .map((field, index) => (
                    <div 
                        key={index} 
                        className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`}
                    >
                        <CustomLink
                            to={subjectPage(field.name, !field.is_linked)}
                        >
                            {field.name}
                            {field.learn_count > 0 && ` · ${field.learn_count}`}
                        </CustomLink>
                    </div>
                ))}
                {relatedSubjects.length > 5 && (
                    showAllSubjects ? 
                        <button className='show-button' onClick={() => setShowAllSubjects(false)}>
                            Show less <FaAngleUp style={{marginLeft:'5px', marginTop:'2px'}}/>
                        </button>
                        :
                        <button className='show-button' onClick={() => setShowAllSubjects(true)}>
                            Show more <FaAngleDown style={{marginLeft:'5px', marginTop:'2px'}}/>
                        </button>
                )}
            </div>
        </div>
    );
};

export default CustomTagRel;
