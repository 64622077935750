import React, { useEffect } from 'react';
import { IoClose } from "react-icons/io5";

const WholeScreenModal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const handleScroll = (e) => {
      if (isOpen) {
        e.preventDefault();
        e.stopPropagation();
        window.scrollTo(0, 0);
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      window.addEventListener('scroll', handleScroll, { passive: false });
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className='whole-screen-modal'>
      <div className='modal-header'>
        <button onClick={onClose} className='close-button'>
          <IoClose />
        </button>
      </div>
      <div className='whole-page-modal-content'>
        {children}
      </div>
    </div>
  );
};

export default WholeScreenModal;
