import React, { useContext } from 'react';
import { NavigationPromptContext } from '../Context/NavigationPromptContext';

const CustomLink = ({ to, children, className = '', onClick = () => {}, style = {} }) => {

  const { safeNavigate } = useContext(NavigationPromptContext);

  const handleClick = (e) => {
    if (e.metaKey || e.ctrlKey || e.shiftKey) {
      return;
    }
    e.preventDefault();
    safeNavigate(to);
    onClick();
  };

  return (
    <a 
      href={to} 
      onClick={handleClick}
      className={className}
      style={{ textDecoration: 'none', color: 'inherit', ...style }}
    >
      {children}
    </a>
  );
};

export default CustomLink;
