import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const handleLogin = async (email, password, setIsError, setLoginStatus) => {
  const user = {
    email: email.toLowerCase(),
    password: password,
  };
  try {
    const response = await axios.post("/api/auth/login/", user, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      if (response.data.access && response.data.refresh) {
        await storageUtils.setItem("access_token", response.data.access);
        await storageUtils.setItem("refresh_token", response.data.refresh);
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access}`;
        setIsError(false);
        setLoginStatus("Login successful");
        return response.data;
      } else {
        throw new Error("Access or refresh token missing in response");
      }
    } else if (response.status === 403) {
      setIsError(false);
      setLoginStatus("Email not verified. A new activation email has been sent.");
    } else {
      throw new Error("Invalid response status");
    }
  } catch (error) {
    console.error('Login error:', error);
    setIsError(true);
    setLoginStatus("Invalid email or password");
  }
};


