import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { sendFollowRequest } from '@linko/shared_utils';
import { AuthContext, UserContext } from '../../Context/AppContext';
import { useToast } from '../../Components/ToastNotification';
import CustomAvatar from '../../Components/CustomAvatar';

const UserModal = ({ isOpen, onClose, users, title, followings }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const { userInfo } = useContext(UserContext);
    const navigate = useNavigate();
    const { addToast } = useToast();

  const goToProfile = (id, firstName, lastName) => {
    const profileCode = id * 912;
    const initial = firstName.charAt(0) + lastName.charAt(0);
    navigate(`/profile/${initial}${profileCode}`);
  };

  const handleFollow = async (userId) => {
    try {
      await sendFollowRequest(userId);
      addToast('Follow request sent', { type: 'success' });
    } catch (error) {
      console.error('Error sending follow request:', error);
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content note-likes">
        <div style={{width:'100%', height:'30px', display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'10px'}}>
          <h2 style={{fontSize:'20px', fontWeight:'600'}}>{title}</h2>
          <button className="close-button" style={{paddingBottom:'3px'}} onClick={onClose}>&times;</button>
        </div>
        <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
          {users?.map((user) => {
            const isFollowing = followings.some(following => following.id === user.id);
            return (
              <div key={user.id} style={{width:'100%', display:'flex', alignItems:'center', margin:'10px 0'}}>
                <CustomAvatar size={25} firstName={user.first_name} lastName={user.last_name}/>
                <div className="user-info" onClick={() => {
                  goToProfile(user.id, user.first_name, user.last_name);
                  onClose();
                }}>
                  <div className="user-name hover-to-green">{user.first_name} {user.last_name}</div>
                </div>
                {isAuthenticated && !isFollowing && user.id !== userInfo?.id &&
                  <button className='linko-button linko-button--tertiary' onClick={() => handleFollow(user.id)} style={{marginLeft:'auto'}}>
                    Follow
                  </button>
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default UserModal;
