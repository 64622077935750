import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaPencilAlt, FaTags, FaUsers, FaLightbulb } from 'react-icons/fa';
import SampleSubjectPage from '../../static/images/sample-subject-page.png';
import SampleNote from '../../static/images/sample-note.png';
import SteveJobs from '../../static/images/steve-jobs-demo.png';
import PsychologyPage from '../../static/images/psychology-knowledge-tree-demo.png';
import EmptyNote from '../../static/images/empty-note-demo.png';
import SmartRevisit from '../../static/images/smart-revisit-demo.png';
import FollowingTab from '../../static/images/following-tab.png';
import FollowingTab2 from '../../static/images/resource-page-following-tab-demo.png';

// Import your feature demo images here
// import SmartNoteMakingDemo from '../../static/images/smart-note-making-demo.png';
// import CommunityBenefitDemo from '../../static/images/community-benefit-demo.png';
// import SmartReviewDemo from '../../static/images/smart-review-demo.png';

const AGLandingPage = () => {
    const [isVisible, setIsVisible] = useState({
        hero: false,
        features: false,
        cta: false
    });
    const heroRef = useRef(null);
    const featuresRef = useRef(null);
    const ctaRef = useRef(null);

    const texts = ['Lifelong Learners', 'Active Thinkers', 'Deep Explorers'];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [displayText, setDisplayText] = useState(texts[0]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(prev => ({ ...prev, [entry.target.id]: true }));
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (heroRef.current) observer.observe(heroRef.current);
        if (featuresRef.current) observer.observe(featuresRef.current);
        if (ctaRef.current) observer.observe(ctaRef.current);

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setDisplayText(texts[currentTextIndex]);
    }, [currentTextIndex]);

    return (
        <div className='ag-landing-page'>
            <div id="hero" ref={heroRef} className={`hero-section ${isVisible.hero ? 'visible' : ''}`}>
                <div className='hero-content'>
                    <h1 className='hero-title'>
                        For <span className="animated-text-wrapper">
                            <span className="animated-text">{displayText}</span>
                        </span>
                    </h1>
                    <p className='hero-subtitle'>
                        Linko empowers you to manage and explore knowledge like never before.
                    </p>
                    <Link to="/register" className='cta-button primary'>
                        Sign up for early access <FaArrowRight />
                    </Link>
                </div>
                <div className='hero-animation'>
                    <img src={SteveJobs} alt="Sample Subject Page" className="subject-page" />
                    <img src={SampleNote} alt="Sample Note" className="note-img" />
                </div>
            </div>

            <div id="features" ref={featuresRef} className={`features-section ${isVisible.features ? 'visible' : ''}`}>
                <h2>Empower Your Learning Experience</h2>
                <div className='feature-grid'>
                    <div className='feature-item'>
                        <div className='feature-content'>
                            <FaPencilAlt className='feature-icon' />
                            <h3>Simple Note-Making</h3>
                            <p>Experience a clean and intuitive note-making process that lets you focus on capturing your deep thoughts.</p>
                        </div>
                        <div className='feature-demo'>
                            <img src={EmptyNote} alt="Smart Note-Making Demo" />
                        </div>
                    </div>
                    <div className='feature-item reverse'>
                        <div className='feature-content'>
                            <FaTags className='feature-icon' />
                            <h3>Hands-free Hierarchical Labeling</h3>
                            <p>Like a librarian, Linko categorizes your ideas into a hierarchical learning subject system shared by the community.</p>
                        </div>
                        <div className='feature-demo'>
                            <img src={PsychologyPage} alt="Community Benefit Demo" />
                        </div>
                    </div>
                    <div className='feature-item'>
                        <div className='feature-content'>
                            <FaUsers className='feature-icon' />
                            <h3>Learn with Friends</h3>
                            <p>Share knowledge with friends and like-minded learners, get inspired by their learning journeys. </p>
                        </div>
                        <div className='feature-demo'>
                            <img src={FollowingTab} alt="Community Sharing Demo" />
                        </div>
                    </div>
                    <div className='feature-item reverse'>
                        <div className='feature-content'>
                            <FaLightbulb className='feature-icon' />
                            <h3>Smart Revisit</h3>
                            <p>Linko intelligently brings back important notes and ideas at the right time to reinforce your learning.</p>
                        </div>
                        <div className='feature-demo'>
                            <img src={SmartRevisit} alt="Smart Review Demo" />
                        </div>
                    </div>
                </div>
            </div>

            <div id="cta" ref={ctaRef} className={`cta-section ${isVisible.cta ? 'visible' : ''}`}>
                <h2>Ready to Amplify Your Learning?</h2>
                <p>Join Linko today and unlock the full potential of your knowledge.</p>
                <Link to="/register" className='cta-button primary'>
                    Get Started Now <FaArrowRight />
                </Link>
            </div>
        </div>
    );
};

export default AGLandingPage;
