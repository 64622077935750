import React from 'react';
import { useLocation } from 'react-router-dom';
import NoteCard from '../NoteCard/NoteCard';
import ResourceCard from '../ResourceCard/ResourceCard';
import Loader from '../Loader';
import NoContentPlaceholder from './NoContentPlaceholder';
import { getSingleNote } from '@linko/shared_utils';
const TabLibrary = ({
    library,
    setLibrary,
    setNotes,
    isFetchingMoreLibrary,
    hasMoreLibrary,
    }) => { 

    const location = useLocation();
    const profilePage = location.pathname.startsWith('/profile/');
    const subjectPage = location.pathname.startsWith('/s/');
    const resourcePage = location.pathname.startsWith('/r/');
    const shareView = profilePage ? true : false;

    const deleteNote = (noteId) => {
        setLibrary(library.filter(item => item.id !== noteId));
        if (setNotes) {
            setNotes(prevNotes => prevNotes.filter(note => note.id !== noteId));
        }
    };

    const updateNoteContent = (updatedNote) => {
        setLibrary(library.map(item => item.id === updatedNote.id ? updatedNote : item));
        if (setNotes) {
            setNotes(prevNotes => prevNotes.map(note => note.id === updatedNote.id ? updatedNote : note));
        }
    };

    const updateSingleNote = (noteId) => {
        getSingleNote(noteId)
        .then((response) => {
            const updatedNote = response.data.note;
            setLibrary(library.map(item => item.id === noteId ? updatedNote : item));
            if (setNotes) {
                setNotes(prevNotes => prevNotes.map(note => note.id === noteId ? updatedNote : note));
            }
        })
        .catch((error) => {
            console.error('Error fetching note:', error.response ? error.response.data : error.message);
        });
    }

    const deleteResource = (id) => {
        setLibrary(library.filter(item => item.resource.id !== id));
    };

    const updateResource = (updatedResource) => {
        setLibrary(library.map(item => item.id === updatedResource.id ? updatedResource : item));
    };

    if ( library && library.length === 0 && !isFetchingMoreLibrary ) {
        return <NoContentPlaceholder activeTab={'library'}/>;
    }
    
    return (
        <div>
            <div className='cards-container'>
                {library && library.map((item, index) => {
                    const isNote = item.hasOwnProperty('note');
                    const isFirstResource = !isNote && library.slice(0, index).every(i => i.hasOwnProperty('note'));
                    return (
                        <React.Fragment key={item.id}>
                            {isFirstResource && resourcePage && (
                                <div className="feed-resource-divider">Related resources</div>
                            )}
                            <div style={{width:'100%'}}>
                                {isNote ? 
                                    <NoteCard 
                                        key={`N${item.id}`}
                                        note={item} 
                                        onDelete={deleteNote} 
                                        onUpdate={updateNoteContent} 
                                        shareView={shareView}
                                        updateSingleNote={updateSingleNote}
                                    />
                                    :
                                    <ResourceCard 
                                        key={`R${item.id}`}
                                        resource={item} 
                                        onDelete={deleteResource}
                                        onUpdate={updateResource}
                                        shareView={shareView}
                                    />
                                }
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
            {hasMoreLibrary && isFetchingMoreLibrary ? 
                <div className='tab-content-loader'>
                    <Loader/>
                </div>
                :
                <div style={{height:'10px'}}></div>
            }
            {!isFetchingMoreLibrary && library.length === 0 &&
                <p className='no-content-placeholder' >No content yet</p>
            }
            {!hasMoreLibrary && library.length >= 10 ? 
                <p style={{paddingLeft:'24px', fontStyle:'italic', margin:'30px 0'}}>No more content</p>
                :
                <div style={{height:'30px'}}></div>
            }
        </div>
    );
};

export default TabLibrary;