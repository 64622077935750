import React from 'react';

const ModalOverlay = ({ children, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      {children}
    </div>
  );
};

export default ModalOverlay;