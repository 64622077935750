import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchSuggestedSubjects = async (resourceId, noteId) => {
    try {
      const accessToken = await storageUtils.getItem('access_token');
      if (!accessToken) {
        throw new Error('No access token found');
      }

      let payload = {};
      if (resourceId) {
        payload = { resource_id: resourceId };
      } else if (noteId) {
        payload = { note_id: noteId };
      }
  
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
  
      const response = await axios.get(`/api/subject/tag_suggest/`, { headers, params: payload });
      
      return response.data;
  
    } catch (error) {
      console.error('Error fetching related subjects:', error);
      throw error;
    }
  };