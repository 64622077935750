import React, { useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
// Icons
import { FaLock } from "react-icons/fa";
import { BiSolidLike } from "react-icons/bi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const NoteInfo = ({
    contentOverflowed,
    isExpanded,
    setIsExpanded,
    noteProp,
    noteLikes,
    formattedTime,
    seeNoteLikes,
    shareView,
    currentNote,
}) => {

    const { safeNavigate } = useContext(NavigationPromptContext);

    const friendProfile = (user) => {
        const initial = user.first_name.charAt(0).toLowerCase() + user.last_name.charAt(0).toLowerCase();
        const encodedId = user.id * 912;
        safeNavigate(`/profile/${initial}${encodedId}`);
    };
    
    return (
        <div>
            {shareView ? 
                <div className='note-last-edited'>
                  {contentOverflowed && (
                    <button onClick={() => setIsExpanded(!isExpanded)} className='note-expand-button'>
                      {isExpanded ? 
                      <>
                        Show less
                        <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} />
                      </> : 
                      <>
                        Show more
                        <FaAngleDown style={{marginLeft: '5px', marginTop:'2px'}} />
                      </>
                      }
                    </button>
                  )}
                  {currentNote ? 
                    <p style={{color:'#666666', fontSize:'14px', margin: '8px', textAlign: 'right', fontStyle: 'italic'}}>
                      Written by 
                      <span onClick={() => friendProfile(noteProp.user)} style={{fontWeight:'600', cursor:'pointer'}}> {noteProp.user.first_name} </span>
                      on {formattedTime}
                    </p>
                    :
                    <p style={{color:'#666666', fontSize:'14px', margin: '8px', textAlign: 'right', fontStyle: 'italic'}}>
                      {formattedTime}
                    </p>
                  } 
                </div>
                :
                <div className='note-last-edited'>
                  {contentOverflowed && (
                  <button onClick={() => setIsExpanded(!isExpanded)} className='note-expand-button'>
                  {isExpanded ? 
                    <>
                      Show less
                      <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} />
                    </> : 
                    <>
                      Show more
                      <FaAngleDown style={{marginLeft: '5px', marginTop:'2px'}} />
                    </>
                    }
                  </button>
                  )}
                  {noteProp.private ?
                    <FaLock className='note-lock-icon' />
                    :
                    (!shareView && noteLikes?.length > 0 && (
                      <div onClick={seeNoteLikes} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                        <BiSolidLike className='note-lock-icon' style={{color: '#2e2e2e86', padding: '6px 0px 6px 6px' }} />
                        <p>{noteLikes.length}</p>
                      </div>
                    ))
                  }
                  <p>{formattedTime}</p>
                </div>
            }
        </div>
    );
};

export default NoteInfo;