import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import { fetchUserHeatmap } from '@linko/shared_utils';

const DAYS_IN_WEEK = 7;
const WEEKS_TO_SHOW = 16; // 4 months approximately

const Heatmap = () => {
  const [heatmapData, setHeatmapData] = useState([]);

  const fetchHeatmapData = async () => {
    const data = await fetchUserHeatmap();
    setHeatmapData(data);
  };

  useEffect(() => {
    setHeatmapData([]);
    fetchHeatmapData();
  }, []);

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (isNaN(date)) {
      console.error('Invalid date:', date);
      return 'Invalid date';
    }
    return date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const getColor = (count) => {
    if (!count) return 'color-empty';
    return count > 4 ? 'color-github-4' : `color-github-${count}`;
  };

  const renderSquares = () => {
    const squares = [];
    const today = new Date();
    const startDate = new Date(today.getTime() - (DAYS_IN_WEEK * WEEKS_TO_SHOW - 1) * 24 * 60 * 60 * 1000);
    const dataMap = new Map(heatmapData.map(item => [item.date, item]));

    for (let week = 0; week < WEEKS_TO_SHOW; week++) {
      const weekSquares = [];
      for (let day = 0; day < DAYS_IN_WEEK; day++) {
        const date = new Date(startDate.getTime() + (week * 7 + day) * 24 * 60 * 60 * 1000);
        const dateString = date.toISOString().split('T')[0];
        const value = dataMap.get(dateString);
        
        weekSquares.push(
          <Tippy
            key={dateString}
            content={
              value ? (
                <div style={{color:'#999999', fontSize:'12px'}}>
                  {value.note > 0 && <>{value.note} note{value.note > 1 ? 's' : ''}</>}
                  {(value.note !== 0 && value.resource !== 0) && ' and '}
                  {value.resource > 0 && <>{value.resource} resource{value.resource > 1 ? 's' : ''}</>}
                  <br />
                  on {formatDate(dateString)}
                </div>
              ) : 'No data'
            }
            placement="bottom"
            arrow={true}
            theme='light-border'
            hideOnClick={true}
            delay={[0, 0]}
          >
            <div
              className={`heatmap-square ${getColor(value?.count)}`}
              data-testid={`heatmap-cell-${week}-${day}`}
            />
          </Tippy>
        );
      }
      squares.push(
        <div key={`week-${week}`} className="heatmap-week">
          {weekSquares}
        </div>
      );
    }
    return squares;
  };

  const renderMonthLabels = () => {
    const months = [];
    let currentMonth = '';
    const today = new Date();
    const startDate = new Date(today.getTime() - (DAYS_IN_WEEK * WEEKS_TO_SHOW - 1) * 24 * 60 * 60 * 1000);

    for (let i = 0; i < WEEKS_TO_SHOW; i++) {
      const date = new Date(startDate.getTime() + i * 7 * 24 * 60 * 60 * 1000);
      const monthName = date.toLocaleString('default', { month: 'short' });
      if (monthName !== currentMonth) {
        months.push(
          <div
            key={`${monthName}-${i}`}
            className="month-label"
            style={{ left: `${(i * 100) / WEEKS_TO_SHOW}%` }}
          >
            {monthName}
          </div>
        );
        currentMonth = monthName;
      }
    }
    return months;
  };

  return (
    <div className='heatmap' role='heatmap'>
      <div className="month-labels">
        {renderMonthLabels()}
      </div>
      <div className="heatmap-container">
        {renderSquares()}
      </div>
    </div>
  );
};

export default Heatmap;