import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const postNote = async (noteData) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }

        const response = await axios.post('/api/note/', noteData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response;
    } catch (error) {
        console.error('Error posting note:', error);
        throw error;
    }
}
