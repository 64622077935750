import react, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchMyData, fetchUserFollow, getNotifCount, fetchRevisitData, fetchUserSubject } from '@linko/shared_utils';

export const UserContext = createContext();
export const FollowContext = createContext();
export const FocusContext = createContext();
export const AuthContext = react.createContext();
export const AddNoteCardContext = react.createContext();
export const OnboardingContext = react.createContext();
export const LoadingContext = createContext();
export const RevisitContext = createContext();

export function AppContextProvider({ children }) {

  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [followings, setFollowings] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [requesting, setRequesting] = useState([]);
  const [focus, setFocus] = useState(false);
  const [addNoteCard, setAddNoteCard] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [notifCount, setNotifCount] = useState(0);
  const [newFeedCount, setNewFeedCount] = useState(0);
  const [newRequestCount, setNewRequestCount] = useState(0);
  const [userNoteCount, setUserNoteCount] = useState(0);
  const [userSubject, setUserSubject] = useState([]);

  const [revisitSubject, setRevisitSubject] = useState('');
  const [revisitNote, setRevisitNote] = useState(null);
  const [isShufflingSubject, setIsShufflingSubject] = useState(false);

  const [userCounts, setUserCounts] = useState([]);

  // Authenticated
  const checkAuthStatus = () => {
    const token = localStorage.getItem('access_token');
    return token !== null && token !== '';
  };
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus());

  useEffect(() => {
    const protectedRoutes = ['/login', '/register', '/forget_password', '/'];
    if (isAuthenticated && protectedRoutes.includes(location.pathname)) {
      navigate('/my_linko');
    }
  }, [location, isAuthenticated, navigate]);

  const fetchUserInfo = async () => {
    const data = await fetchMyData();
    setUserInfo(data);
  };

  const fetchNotifCount = async () => {
    try {
      const data = await getNotifCount();
      setNotifCount(data.new_likes + data.new_follow_requests);
      setNewRequestCount(data.new_follow_requests);
      setNewFeedCount(data.new_feed);
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  const fetchUserSubjectData = async () => {
    const data = await fetchUserSubject();
    setUserSubject(data.userSubject);
    setUserCounts(data.userCounts);
    setUserNoteCount(data.userCounts.note_count);
};

  const fetchFollowData = async () => {
    const data = await fetchUserFollow();
    setFollowings(data.following);
    setFollowers(data.follower);
    setPendingRequests(data.request_pending);
    setRequesting(data.requesting);
  };

  const fetchUserData = async () => {
    setIsLoading(true);
    await Promise.all([
      fetchUserInfo(),
      fetchUserSubjectData(),
      fetchFollowData(),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isShufflingSubject && userNoteCount >= 1) {
      fetchRevisitData(setRevisitSubject, setRevisitNote, setIsShufflingSubject);
    }
  }, [isShufflingSubject, userNoteCount]);

  useEffect(() => {    
    if (isAuthenticated) {
      fetchUserData();
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  const userContextValue = {
    userInfo, 
    setUserInfo, 
    fetchUserInfo, 
    notifCount, 
    setNotifCount, 
    fetchNotifCount, 
    newFeedCount, 
    setNewFeedCount, 
    newRequestCount,
    userSubject,
    userCounts,
    userNoteCount
  };
  
  return (
    <RevisitContext.Provider value={{ revisitSubject, revisitNote, isShufflingSubject, setIsShufflingSubject }}>
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
          <AddNoteCardContext.Provider value={{ addNoteCard, setAddNoteCard, newNoteContent, setNewNoteContent }}>
            <FocusContext.Provider value={{ focus, setFocus }}>
              <FollowContext.Provider value={{ fetchFollowData, followings, setFollowings, followers, setFollowers, pendingRequests, setPendingRequests, requesting, setRequesting }}>
                <UserContext.Provider value={userContextValue}>
                  <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, checkAuthStatus}}>
                    {children}
                  </AuthContext.Provider>
                </UserContext.Provider>
              </FollowContext.Provider>
            </FocusContext.Provider>
          </AddNoteCardContext.Provider>
        </LoadingContext.Provider>
    </RevisitContext.Provider>
  );
}

export default AppContextProvider;