import React, {useState, useEffect, useRef} from 'react';
import YouTube from 'react-youtube';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

const VideoPlayer = ({videoId, setOmitPreview, description}) => {
    const [width, setWidth] = useState('100%');
    const playerRef = useRef(null);
    const containerRef = useRef(null);
    const [showFullDescription, setShowFullDescription] = useState(false);

    const updatePlayerSize = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            setWidth(containerWidth);
        }
    };

    useEffect(() => {
        updatePlayerSize();
        window.addEventListener('resize', updatePlayerSize);
        return () => {
            window.removeEventListener('resize', updatePlayerSize);
        };
    }, []);

    const formatDescription = (text) => {
        const timestampRegex = /^(\d{1,2}):(\d{2})(?::(\d{2}))?\s*(.*)/;
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        
        const lines = text.split('\n');
        let index = [];
        let remainingDescription = [];

        for (const line of lines) {
            if (timestampRegex.test(line)) {
                index.push(line);
            } else {
                remainingDescription.push(line);
            }
        }

        const formatLine = (line) => {
            return line
                .replace(timestampRegex, (match, hours, minutes, seconds, content) => {
                    const totalSeconds = seconds
                        ? parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds)
                        : parseInt(hours) * 60 + parseInt(minutes);
                    const formattedTime = seconds
                        ? `${hours}:${minutes}:${seconds}`
                        : `${hours}:${minutes}`;
                    return `<a href="#" class="timestamp" data-seconds="${totalSeconds}">${formattedTime}</a> ${content || ''}`;
                })
                .replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer" class="external-link">${url}</a>`);
        };

        const formattedIndex = index.map(formatLine).join('\n');
        const formattedDescription = remainingDescription.map(formatLine).join('\n');

        const visibleDescription = showFullDescription ? formattedDescription : formattedDescription.split('\n').slice(0, 3).join('\n');

        return (
            <div>
                {index.length > 0 && (
                    <div
                        dangerouslySetInnerHTML={{ __html: formattedIndex }}
                        onClick={handleTimestampClick}
                        style={descriptionStyle}
                    />
                )}
                {remainingDescription.length > 0 && (
                    <>
                        <div
                            dangerouslySetInnerHTML={{ __html: visibleDescription }}
                            onClick={handleTimestampClick}
                            style={descriptionStyle}
                        />
                        {remainingDescription.length > 3 && (
                            <button 
                                onClick={() => setShowFullDescription(!showFullDescription)} 
                                className='note-expand-button' 
                                style={{padding:'5px 0'}}
                            >
                                {showFullDescription ? 'Show less' : 'Show more'}
                                {showFullDescription ? <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} /> : <FaAngleDown style={{marginLeft: '5px', marginTop:'0px'}} />} 
                            </button>
                        )}
                    </>
                )}
            </div>
        );
    };

    const handleTimestampClick = (e) => {
        if (e.target.classList.contains('timestamp')) {
            e.preventDefault();
            const seconds = parseInt(e.target.dataset.seconds);
            if (playerRef.current) {
                playerRef.current.seekTo(seconds);
            }
        }
    };

    const descriptionStyle = {
        marginTop: '30px',
        width: '100%',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        lineHeight: '1.5',
        fontSize: '16px'
    };

    return (
        <div ref={containerRef} style={{ width: '100%' }}>
            <YouTube
                videoId={videoId}
                opts={{
                    width: '100%',
                    height: `${width * 0.5625}px`,
                    playerVars: {
                        autoplay: 0,
                        controls: 1,
                        rel: 0,
                        showinfo: 0
                    }
                }}
                onReady={(event) => {
                    playerRef.current = event.target;
                }}
                onError={() => setOmitPreview(true)}
            />
            {description && (
                <div style={{
                    marginTop: '30px',
                    width: '100%',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    lineHeight: '1.5',
                    fontSize: '14px'
                }}>
                    {formatDescription(description)}
                </div>
            )}  
        </div>
    );
};

export default VideoPlayer;
