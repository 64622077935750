import axios from '../Middleware/axios';

export const register = async (firstName, lastName, email, password) => {
    try {
        const response = await axios.post('/api/auth/register/', {
            first_name: firstName,
            last_name: lastName,
            email: email.toLowerCase(),
            password: password,
        });
        return response.data;
    } catch (error) {
        console.error('Registration error:', error);
        throw error;
    }
}

export const activateAccount = async (uidb64, token) => {
    try {
        const response = await axios.get(`/api/auth/activate/${uidb64}/${token}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response;
    } catch (error) {
        console.error('Account activation error:', error);
        throw error;
    }
}