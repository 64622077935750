import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const deleteNote = async (noteId) => {
    try {
        console.log('Deleting note from shared utils');
        const accessToken = await storageUtils.getItem('access_token');
        // if (!accessToken) {
        //     throw new Error('No access token found');
        // }
        const response = await axios.delete(`/api/note/${noteId}/`, {
            headers: { 
                Authorization: `Bearer ${accessToken}`,
            },
        });
        console.log('response in deleteNote', response);
        return response;
    } catch (error) {
        console.error('Error deleting note:', error.response?.data || error.message);
        throw error;
    }
};