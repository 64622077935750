import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchResources = async (
    limit,
    resourcesOffset,
    filterType = [],
    filterFinished = null,
    resourceFilterKnowledge = [],
    resources = [],
    setResources,
    setResourcesOffset,
    setHasMoreResources,
    setIsFetchingMoreResources,
    signal
) => {

    if (signal?.aborted) {
        return;
    }

    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }
        if (signal?.aborted) {
            return;
        }

        const headers = { Authorization: `Bearer ${accessToken}` };
        const payload = {
            limit,
            offset: resourcesOffset,
            ...(Array.isArray(filterType) && filterType.length > 0 && { filter_type: filterType.join(',') }),
            ...(filterFinished !== null && { filter_finished: filterFinished }),
            ...(Array.isArray(resourceFilterKnowledge) && resourceFilterKnowledge.length > 0 && { filter_knowledge: resourceFilterKnowledge.join(',') }),
        };

        const config = { headers, params: payload, ...(signal && { signal }) };

        const response = await axios.get('/api/resource/', config);

        if (response.data?.results) {
            const newResources = response.data.results.filter(
                newResource => !Array.isArray(resources) || !resources.some(resource => resource.id === newResource.id)
            );

            setResources(prevResources => [...(Array.isArray(prevResources) ? prevResources : []), ...newResources]);
            setHasMoreResources(response.data.results.length === limit);

            if (newResources.length > 0) {
                setResourcesOffset(prevOffset => prevOffset + newResources.length);
            }
        }
        setIsFetchingMoreResources?.(false);
    } catch (error) {
        if (axios.isCancel(error)) {
            setIsFetchingMoreResources(true);
        } else {
            console.error('Failed to fetch resources:', error);
        }
    }
};

export const fetchBestUnlearned = async (subject_ids) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }

        const response = await axios.get(`/api/resource/best_unlearned/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: { subject_ids: subject_ids.join(',') },
        });

        return response;
    } catch (error) {
        console.error('Failed to fetch best unlearned resources:', error);
        throw error;
    }
};
