import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Tippy from '@tippyjs/react';
// Context
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
// Components
import RichEditor from './RichEditor';
import Loader from '../Loader';
import FocusMode from './FocusMode';
import PrivateToggle from './PrivateToggle';
import LinkedResource from './LinkedResource';
// Icons
import { CgExpand } from "react-icons/cg";

const EditNoteCard = ({
  isLoading,
  showTextArea,

  handleSubmit,
  handleCancel,
  updateSingleNote,

  title,
  setTitle,
  note,
  setNote,
  isPrivate,
  setIsPrivate,
  noteResource,
  setNoteResource,
  editorContent,
  setEditorContent,
}) => {

  const [isFullScreen, setIsFullScreen] = useState(false);
  const { setUnsavedChanges } = useContext(NavigationPromptContext);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  // Store initial values for comparison
  const initialValues = useRef({
    title,
    note,
    isPrivate,
    editorContent
  });

  useEffect(() => {
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouch);
  }, []);

  const checkForChanges = useCallback(() => {
    const hasChanges = 
      title !== initialValues.current.title ||
      note !== initialValues.current.note ||
      isPrivate !== initialValues.current.isPrivate;
    
    setUnsavedChanges(hasChanges);
    return hasChanges;
  }, [title, note, isPrivate, setUnsavedChanges]);

  const handleEditorChange = useCallback((markdown) => {
    setNote(markdown);
    setEditorContent(markdown);
    checkForChanges();
  }, [setNote, setEditorContent, checkForChanges]);

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    checkForChanges();
  };

  const togglePrivate = () => {
    setIsPrivate(!isPrivate);
    checkForChanges();
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleTitlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');
    const newTitle = pastedText.replace(/\n/g, ' ');
    setTitle(newTitle);
    checkForChanges();
  };

  const handleCancelClick = async (e) => {
    e.preventDefault();
    await handleCancel(e);
  };

  if (isFullScreen) {
    return (
      <FocusMode 
        handleSubmit={handleSubmit}
        title={title}
        handleTitleChange={handleTitleChange}
        handleEnterKey={handleEnterKey}
        handleTitlePaste={handleTitlePaste}
        setIsFullScreen={setIsFullScreen}
        isPrivate={isPrivate}
        handlePrivateChange={togglePrivate}
        handleCancel={handleCancel}
        isLoading={isLoading}
        note={note}
        handleEditorChange={handleEditorChange}
        editorContent={editorContent}
      />
    );
  }

  return (
    <div className='add-note-card'>
      {isLoading ? <Loader /> : (
        <>
          <form onSubmit={handleSubmit}>
            <div className='add-note-card-header'>
              <textarea 
                value={title} 
                onChange={handleTitleChange}
                onKeyDown={handleEnterKey} 
                onPaste={handleTitlePaste}
                placeholder='Title' 
                className='add-note-title'
                rows={1}  
              />
              <Tippy 
                content="Enter focus mode"
                placement="bottom"
                arrow={true}
                theme='light-border'
                hideOnClick={true} 
                delay={[0, 0]} 
                touch={false}  // Disable on touch devices
                disabled={isTouchDevice}  // Completely disable on touch devices
              >
                <div>
                  <CgExpand 
                    title='Enter focus mode'
                    size={50} 
                    onClick={() => setIsFullScreen(true)}
                  />
                </div>
              </Tippy>
            </div>  
            <RichEditor 
              key={note?.id}
              value={editorContent || ''}
              onChange={handleEditorChange}
              placeholder='Write your note...'
            />
          </form>
          <div className='cancel-or-submit'>
            <LinkedResource noteResource={noteResource} setNoteResource={setNoteResource} noteId={note?.id} updateSingleNote={updateSingleNote}/>
            <PrivateToggle isPrivate={isPrivate} handlePrivateChange={togglePrivate} />
            <button onClick={handleCancelClick} className='linko-button linko-button--cancel mr-10'>Cancel</button>
            <button onClick={handleSubmit} className='linko-button linko-button--primary' disabled={isLoading}>Save</button>
          </div>
        </>
      )}
    </div>
  );
};

export default EditNoteCard;
