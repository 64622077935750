import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils';

export const fetchMyData = async () => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    const response = await axios.get('/api/user/me/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error.response ? error.response.status : error.message);
    throw error;
  }
};

export const fetchUserData = async (userId) => {
  try {
    if (!userId) {
      throw new Error('User ID is required');
    }
    
    // Get access token if available
    const accessToken = await storageUtils.getItem('access_token');
    const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    
    const response = await axios.get(`/api/user/${userId}/`, { headers });
    return response;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const fetchUserHeatmap = async () => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    const response = await axios.get('/api/user/heatmap/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user heatmap:', error);
    throw error;
  }
};
