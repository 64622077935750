import React from 'react';
import AddBook from './AddBook';
import AddLink from './AddLink';
import ModalOverlay from '../ModalOverlay';

const AddToLinkoModal = ({ setSelectedResource, content, onClose }) => {
    let ComponentToRender;
    switch(content) {
        case 'Book':
            ComponentToRender = AddBook;
            break;
        case 'Link':
            ComponentToRender = AddLink; 
            break;
        default:
            ComponentToRender = null;
    }

    return (
        <ModalOverlay>
            <div className="add-to-linko-modal">
                {ComponentToRender && <ComponentToRender setSelectedResource={setSelectedResource} onClose={onClose}/>}
            </div>
        </ModalOverlay>
    );
};

export default AddToLinkoModal;
