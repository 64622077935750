import React from 'react';
import { useNavigation } from '../Context/NavigationContext';
import { FaArrowLeft } from "react-icons/fa6";

const BackButton = () => {
  const { goBack, canGoBack } = useNavigation();

  const handleClick = () => {
    goBack();
  };

  return (
    <div className='back-button-wrapper'>
      <button 
        onClick={handleClick} 
        className='linko-button linko-button--cancel back-button' 
        disabled={!canGoBack}
      >
        <FaArrowLeft size={24}/>
      </button>
    </div>  
  );
};

export default BackButton;