import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../Context/AppContext';
import { useToast } from '../../Components/ToastNotification';
import { putUserData, forgetPassword } from '@linko/shared_utils';

export const NameChangeModal = ({ isOpen, onClose }) => {
    const { userInfo, fetchUserInfo } = useContext(UserContext);
    const { addToast } = useToast();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        if (isOpen && userInfo) {
        setFirstName(userInfo.first_name || '');
        setLastName(userInfo.last_name || '');
        }
    }, [isOpen, userInfo]);

    const handleNameChange = async (e) => {
        e.preventDefault();
        if (firstName === '' || lastName === '') {
            window.alert('Name cannot be empty');
            setFirstName(userInfo.first_name);
            setLastName(userInfo.last_name);
            return;
        }
        if (firstName === userInfo.first_name && lastName === userInfo.last_name) {
            onClose();
            return;
        }
        const response = await putUserData({first_name: firstName, last_name: lastName});
        if (response.status === 200) {
            addToast('Name updated!', {appearance: 'success'});
            fetchUserInfo();
            onClose();
        } else {
            window.alert('Failed to update name');
        }
    }

  if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="name-change-modal">
                <div className="modal-header">
                    <h2>Name</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="input-group">
                        <label htmlFor="firstName">FIRST NAME</label>
                        <input
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="lastName">LAST NAME</label>
                        <input
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="linko-button linko-button--cancel" onClick={onClose}>Cancel</button>
                    <button className="linko-button linko-button--primary" onClick={handleNameChange}>Save</button>
                </div>
            </div>
        </div>
    );
};

export const ResetPasswordModal = ({ isOpen, onClose }) => {
    const { userInfo } = useContext(UserContext);
    const { addToast } = useToast();

    const handleSubmit = async () => {        
        try {
          const response = await forgetPassword(userInfo?.email);
          if (response.status === 200) {
            addToast('Reset password link sent. Please check your email.', {appearance: 'success'});
            onClose()
          }
        } catch (error) {
            window.alert('An error occurred while sending the reset password link. Please try again later.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="name-change-modal">
                <div className="modal-header">
                    <h2>Get a reset link</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="email">
                        {userInfo?.email}
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="linko-button linko-button--cancel" onClick={onClose}>Cancel</button>
                    <button className="linko-button linko-button--primary" onClick={handleSubmit}>Send</button>
                </div>
            </div>
        </div>
    )
}