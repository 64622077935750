import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const putUserData = async (userData) => {
  if (!userData || typeof userData !== 'object') {
    throw new Error('Invalid userData: must be a non-null object');
  }

  const accessToken = storageUtils.getItem('access_token');
  if (!accessToken) {
    throw new Error('No access token found');
  }

  const payload = Object.entries(userData).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (Object.keys(payload).length === 0) {
    throw new Error('No valid data provided for update');
  }

  try {
    const response = await axios.put('/api/user/update_info/', payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response;
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};