import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const putNote = async (noteId, noteData, subject_id_list, custom_tag, resource_id) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }

        const payload = {
            ...(noteData !== undefined && { note: noteData }),
            ...(subject_id_list !== undefined && { knowledge_id_list: subject_id_list }),
            ...(resource_id !== undefined && { resource_id }),
            ...(custom_tag !== undefined && { custom_tag })
        };

        const response = await axios.put(`/api/note/${noteId}/`, payload, {
            headers: { Authorization: `Bearer ${accessToken}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating note:', error.response?.data || error.message);
        if (error.config) {
            console.error('Request config:', error.config);
        }
        if (error.response) {
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
        }
        throw error;
    }
};
