import React, { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { handleFollowAction, sendFollowRequest, profilePage } from '@linko/shared_utils';
import { FollowContext, UserContext } from '../../Context/AppContext';
import { useModal } from '../../Context/ModalContext';
import { useToast } from '../../Components/ToastNotification';
import { FaRegShareFromSquare } from "react-icons/fa6";
import CustomAvatar from '../../Components/CustomAvatar';
import CustomLink from '../../Components/CustomLink';

const AccountPage = () => {
  const [activeTab, setActiveTab] = useState('followers');
  const [requestedUsers, setRequestedUsers] = useState(new Set());
  const { followers, followings, setFollowings, setFollowers, fetchFollowData } = useContext(FollowContext);
  const { userInfo } = useContext(UserContext);
  const { requestConfirm } = useModal();
  const { addToast } = useToast();

  useEffect(() => {
    fetchFollowData();
  }, []);

  const user = {
    name: `${userInfo?.first_name} ${userInfo?.last_name}`,
    username: `@${userInfo?.first_name}${userInfo?.last_name}`,
    followers: followers.length,
    following: followings.length,
  };

  const copyProfileLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(userInfo.profile_link)
        .then(() => {
            addToast('Profile link copied!', {appearance: 'success'});
        })
        .catch(err => {
          console.error('Error writing to clipboard', err);
          addToast('Failed to copy profile link', {appearance: 'error'});
        });
    } else {
      console.error('Clipboard API not supported');
        addToast('Failed to copy profile link', {appearance: 'error'});
    }
}

  const handleFollowRequest = useCallback(async (id) => {
    const response = await sendFollowRequest(id);
    if (response.status === 200) {
      setRequestedUsers(prev => new Set(prev).add(id));
      addToast('Follow request sent.', { appearance: 'success' });
      fetchFollowData();
    } else {
      window.alert('There was an error sending the follow request, please try again later.');
    }
  }, [addToast, fetchFollowData, setRequestedUsers]);

  const handleRemove = useCallback(async (id, name) => {
    const removeMessage = activeTab === 'followers' ? `remove ${name} from your follower list` : `unfollow ${name}`;
    const removeType = activeTab === 'followers' ? 'Remove' : 'Unfollow';
    const action = activeTab === 'followers' ? 'remove follower' : 'unfollow';
    const successMessage = activeTab === 'followers' ? `You removed ${name} from your follower list` : `You unfollowed ${name}`;
    requestConfirm(
      `Are you sure you want to ${removeMessage}?`,
      async () => {
        const response = await handleFollowAction(id, action);
        if (response.status === 200) {
          if (activeTab === 'followers') {
            setFollowers(prevFollowers => prevFollowers.filter((item) => item.id !== id));
          } else {
            setFollowings(prevFollowings => prevFollowings.filter((item) => item.id !== id));
          }
          fetchFollowData();
          addToast(successMessage, { appearance: 'success' });
        } else {
          window.alert('There was an error removing the follower, please try again later.');
        }
      },
      null,
      removeType
    );
  }, [activeTab, requestConfirm, setFollowings, fetchFollowData, addToast]);

  const renderList = useMemo(() => {
    const list = activeTab === 'followers' ? followers : followings;
    return list.map((item) => {
      const userName = `${item.first_name} ${item.last_name}`;
      const userUsername = `@${item.first_name}${item.last_name}`;
      const isFollowing = followings.some(user => user.id === item.id);
      const isRequested = requestedUsers.has(item.id);

      return (
        <div key={item.id} className="user-item">
          <div style={{marginRight:'10px'}}>
            <CustomAvatar size={35} firstName={item.first_name} lastName={item.last_name}/>
          </div>
          <div className="user-info">
            <div className="user-name">
              <CustomLink
                to={profilePage(item.id, item.first_name, item.last_name)}
              >
                {userName}
              </CustomLink>
            </div>
            <div className="user-username">
              {userUsername}
            </div>
          </div>
          {activeTab === 'followers' ? (
            <>
              {!isFollowing && (
                <button 
                  className="linko-button linko-button--tertiary"
                  onClick={() => handleFollowRequest(item.id)}
                  disabled={isRequested}
                  style={{marginRight:'10px'}}
                >
                  {isRequested ? 'Requested' : 'Follow'}
                </button>
              )}
              <button 
                className="linko-button linko-button--cancel"
                onClick={() => handleRemove(item.id, item.first_name)}
              >
                Remove
              </button>
            </>
          ) : (
            <button 
              className="linko-button linko-button--cancel"
              onClick={() => handleRemove(item.id, item.first_name)}
            >
              Unfollow
            </button>
          )}
        </div>
      );
    });
  }, [activeTab, followers, followings, requestedUsers, profilePage, handleFollowRequest, handleRemove]);

  return (
    <div className="profile-page">
      <h1>Account</h1>
      <div className="profile-header">
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{marginRight:'20px'}}>
            <CustomAvatar size={60} firstName={userInfo?.first_name} lastName={userInfo?.last_name}/>
          </div>
          <div className="user-info">
            <h2>{user.name}</h2>
            <p>{user.username}</p>
          </div>
        </div>
        <button className="share-profile" onClick={copyProfileLink}>
          <FaRegShareFromSquare size={16} color='#999' style={{marginRight: '5px'}}/>
          Share Profile
        </button>
      </div>
      <div className="tabs">
        <button
          className={activeTab === 'followers' ? 'active' : ''}
          onClick={() => setActiveTab('followers')}
        >
          FOLLOWERS ({user.followers})
        </button>
        <button
          className={activeTab === 'following' ? 'active' : ''}
          onClick={() => setActiveTab('following')}
        >
          FOLLOWINGS ({user.following})
        </button>
      </div>
      <div className="user-list">
        {renderList}
      </div>
    </div>
  );
};

export default AccountPage;
