import React, {useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { handleLogout } from '@linko/shared_utils';
import { AuthContext, UserContext } from '../../Context/AppContext';
import { FaRegUser, FaRegBell, FaRegCommentDots } from "react-icons/fa6";
import { FiMessageCircle } from "react-icons/fi";
import { TbSettings } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";


const NameDropdown = ({userInfo, notificationCount}) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();
    const { setIsAuthenticated } = useContext(AuthContext);
    const { setUserInfo } = useContext(UserContext);

    const handleLogoutPress = async () => {
        try {
            await handleLogout();
            setIsAuthenticated(false);
            setUserInfo(null);
            safeNavigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [location]);

    return (
        <div 
        className="username-dropdown" 
        onMouseEnter={() => setIsDropdownOpen(true)}
        onMouseLeave={() => setIsDropdownOpen(false)} 
        >
            <button className="navbar-user-name" >
                {userInfo && <span>{userInfo.first_name}</span>}
            </button>
            {notificationCount > 0 && <div className="notification-badge"></div>}
            {isDropdownOpen && (
                <div className="name-dropdown-menu">
                    <div className='dropdown-item setting' onClick={() => safeNavigate(`/my_profile/${userInfo.first_name}${userInfo.last_name}`)}>
                        <FaRegUser size={16} color='#999'/>
                        <p>Account</p>
                    </div>
                    <div className='dropdown-item setting' onClick={() => safeNavigate(`/notifications/${userInfo.first_name}${userInfo.last_name}`)}>
                        <FaRegBell size={16} color='#999'/>
                        <p>Notifications</p>
                        {notificationCount > 0 && <div className="notification-count">{notificationCount}</div>}
                    </div>
                    <div className='rx-divider-vertical'></div>
                    <div className='dropdown-item setting' onClick={() => safeNavigate(`/settings/${userInfo.first_name}${userInfo.last_name}`)}>
                        <TbSettings size={16} color='#999'/>
                        <p>Settings</p>
                    </div>
                    <a className='dropdown-item setting' href='https://linko.featurebase.app/' target='_blank'>
                        <FaRegCommentDots size={16} color='#999'/>
                        <p>Feedback</p>
                    </a>
                    <div className='rx-divider-vertical'></div>
                    <div className="logout" onClick={handleLogoutPress}>
                        <FiLogOut size={16} />
                        <p>Logout </p>
                    </div>
                </div>
            )}
      </div>
    );
};

export default NameDropdown;