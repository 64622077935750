import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, LoadingContext } from "../../Context/AppContext";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { handleLogin } from '@linko/shared_utils';
import { GoogleLogin } from '@react-oauth/google';
import axios from "axios";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { setIsAuthenticated } = useContext(AuthContext);
    const { setIsLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const [loginStatus, setLoginStatus] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Activation link error handling
    useEffect(() => {
        const linkError = localStorage.getItem("linkError");
        if (linkError) {
            setIsError(true);
            setErrorMessage(linkError);
            setTimeout(() => {
                localStorage.removeItem("linkError");
            }, 5000);
        }
    }, []);

    // Activation success notice
    useEffect(() => {
        const successNotice = localStorage.getItem("successNotice");
        if (successNotice) {
            setIsError(false);
            setLoginStatus(successNotice);
            setTimeout(() => {
                localStorage.removeItem("successNotice");
            }, 5000);
        }
    }, []);

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            console.log("Google credential received:", credentialResponse);
            
            const response = await axios.post('/api/auth/google_login/', {
                token: credentialResponse.credential
            });
            
            if (response.data.access && response.data.refresh) {
                await localStorage.setItem('access_token', response.data.access);
                await localStorage.setItem('refresh_token', response.data.refresh);
                setIsAuthenticated(true);
                navigate('/my_linko');
            }
        } catch (error) {
            console.error('Google login error:', error.response?.data || error);
            setIsError(true);
            setLoginStatus(error.response?.data?.detail || 'Google login failed');
        }
    };

    const onLoginPress = async () => {
        try {
            if (email === null || email === undefined || password === null || password === undefined) {
                throw new Error("Email or password is missing");
            }
            await handleLogin(email, password, setIsError, setLoginStatus)
        } catch (error) {
            if (error.message === "Broken pipe") {
                console.error("Connection lost");
            } else {
                console.error(error);
            }
        } 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onLoginPress();
    };

    useEffect(() => {
        if (loginStatus === "Login successful") {
            setIsLoading(true);
            setIsAuthenticated(true);
            navigate("/my_linko");
        }
    }, [loginStatus]);

    return (
        <div className="login">
            <div className="login-form-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-form-content">
                        <h3 className="welcome-back">Welcome Back!</h3>
                        <p style={{marginBottom:'15px', color:'#257953'}}>{loginStatus}</p>
                        <div className="login-form-group">
                            <label className="login-form-label">Email</label>
                            <input
                                className="login-form-control"
                                placeholder="Enter email"
                                name="email"
                                type="email"
                                value={email}
                                required
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="login-form-group">
                            <label className="login-form-label">Password</label>
                            <div className="password-input">
                                <input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    className="login-form-control"
                                    placeholder="Enter password"
                                    value={password}
                                    autoComplete="current-password"
                                    required
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    style={{ paddingRight: "50px" }}
                                />
                                <div
                                    onClick={togglePasswordVisibility}
                                    className="password-visibility-icon"
                                >
                                    {showPassword ? (
                                        <HiOutlineEyeOff />
                                    ) : (
                                        <HiOutlineEye />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="login-form-footer">
                            <p className='login-status error-notice'>{errorMessage}</p>
                            <div className="login-form-footer-right">
                                <div className="login-form-link">
                                    <p>
                                        Don't have an account?{" "}
                                        <Link to="/register">Register</Link>
                                    </p>
                                    <Link to="/forget_password">
                                        Forgot password?
                                    </Link>
                                </div>
                                <button className="login-button" type="submit">
                                    Log in
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="google-login-container">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                            setIsError(true);
                                setLoginStatus('Google login failed');
                            }}
                        />
                    </div>
                </form>

            </div>
        </div>
    );
}

export default Login;
