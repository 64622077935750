import React, {useEffect, useState, useRef, useContext} from 'react';
import { createRoot } from 'react-dom/client';
import { AddNoteCardContext } from '../../Context/AppContext';
import tippy, {hideAll} from 'tippy.js';
import { BsChatLeftQuote } from "react-icons/bs";
import { Readability } from '@mozilla/readability';

const ArticleReader = ({articleContent}) => {

    const articleReaderRef = useRef(null);
    const tooltipRef = useRef(null);
    const [hasError, setHasError] = useState(false);
    const [isTooltipInteracted, setIsTooltipInteracted] = useState(false);
    const { setNewNoteContent } = useContext(AddNoteCardContext);
    const [selectedText, setSelectedText] = useState('');
    let tippyInstance = null;
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(articleContent, 'text/html');
          const reader = new Readability(doc);
          const article = reader.parse();
          setContent(article.content);
        };
    
        if (articleContent) {
          fetchContent();
        }
      }, [articleContent]);

    const handleMouseUp = () => {
        const text = window.getSelection().toString().trim();
        const articleContent = document.getElementById('articleContent');
        const clickInsideTooltip = tooltipRef.current && tooltipRef.current.contains(window.getSelection().anchorNode);

        if (text && articleContent && articleContent.contains(window.getSelection().anchorNode)) {
            setSelectedText(text);

            const rect = window.getSelection().getRangeAt(0).getBoundingClientRect();

            hideAll();

            tippyInstance = tippy(articleContent, {
                showOnCreate: true,
                hideOnClick: false,
                trigger: 'manual',
                placement: 'bottom',
                interactive: true,
                theme: 'heavy-shadow',
                getReferenceClientRect: () => rect,
                onHide: () => {
                    setIsTooltipInteracted(false);
                }
            });

            // Create a new div element
            const div = document.createElement('div');
            div.style.display = 'flex';
            div.style.alignItems = 'center';
            div.style.columnGap = '8px';
            div.style.padding = '4px';
            div.style.cursor = 'pointer';
    
            // Create a new p element and icon
            const p = document.createElement('p');
            p.textContent = 'Quote';
            p.style.margin = '0px';
            
            // Create a new icon element
            const iconContainer = document.createElement('span');
            const root = createRoot(iconContainer);
            
            // Store the root instance on the container element
            iconContainer._root = root;
            
            root.render(<BsChatLeftQuote />);
            iconContainer.style.width = '20px';
            iconContainer.style.height = '20px';
            iconContainer.style.fontSize = '18PX';
            iconContainer.style.marginTop = '2px';

            // Append the icon and p elements to the div
            div.appendChild(iconContainer);
            div.appendChild(p);
            
            // Add an event listener for the click event
            p.addEventListener('click', () => {
                setIsTooltipInteracted(true);
                handleConfirm(text);
                tippyInstance.hide();
            });
    
            // Update the tooltip's content
            tippyInstance.setContent(div);

            // Clean up when hiding the tooltip
            tippyInstance.setProps({
                onHidden: () => {
                    // Unmount the React root when the tooltip is hidden
                    if (iconContainer._root) {
                        iconContainer._root.unmount();
                    }
                }
            });
        } else if (!text && !isTooltipInteracted) {
            hideAll();
        }
    };

    const handleConfirm = (text) => {
        setNewNoteContent(text);
        if (tippyInstance) {
            tippyInstance.hide();
        }
        
    }

    const handleError = () => {
        setHasError(true);
        console.error('Error fetching article content');
    }

    const handleLinkClick = (event) => {
        if (!window.confirm('Would you like to go to this URL?')) {
            event.preventDefault();
        }
    }

    if (articleContent === null) {
        return null; 
    }

    return (
        <div className='article-reader' 
            ref={(node) => {
                articleReaderRef.current = node;
            }} 
            onMouseUp={handleMouseUp}
        >
            <div dangerouslySetInnerHTML={{ __html: content }} id='articleContent'/>
        </div>
    );
};

export default ArticleReader;
