import React, { useEffect } from 'react';
import MySubjects from './MySubjects';
import RecentlyAdded from './RecentlyAdded';
import SubjectOverview from './SubjectOverview';
import HalfFooter from '../../Components/NavAndFooter/HalfFooter';
import Heatmap from './Heatmap';

const HomePageRight = ({userSubject}) => {

    useEffect(() => {
        let lastScrollTop = 0;
        const handleScroll = () => {
          const navbar = document.querySelector('.home-page-right');
          const currentScroll = window.scrollY || document.documentElement.scrollTop;
          if (navbar) {
            if (currentScroll > lastScrollTop) {
              // Scrolling down
              navbar.style.top = '0px';
            } else {
              // Scrolling up
              navbar.style.top = '70px';
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile Safari
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []); 

    return (
        <div className='home-page-right'>
            <SubjectOverview userSubject={userSubject}/>
            <MySubjects userSubject={userSubject}/>
            <RecentlyAdded />
            <div className='rx-divider-vertical' style={{margin:'10px 0', padding:'0 10px'}}/>
            <Heatmap />
            <div style={{height:'20px'}}/>
            <HalfFooter />
        </div>
    );
};

export default HomePageRight;