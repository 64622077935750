import React, { useState, useContext, useEffect } from 'react';
import { UserContext, FollowContext } from '../../Context/AppContext';
import { getNotif, getNotifHistory, handleFollowAction, profilePage } from '@linko/shared_utils';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';
import SlimNoteCard from '../../Components/NoteCard/SlimNoteCard';
import { BiSolidLike } from "react-icons/bi";
import { useToast } from '../../Components/ToastNotification';
import CustomLink from '../../Components/CustomLink';
const NotificationPage = () => {
  const [activeTab, setActiveTab] = useState('likes');
  const { setNotifCount, newRequestCount } = useContext(UserContext);
  const { fetchFollowData, pendingRequests } = useContext(FollowContext);
  const [ newNotifications, setNewNotifications] = useState([]);
  const [ notificationsHistory, setNotificationsHistory] = useState([]);
  const { addToast } = useToast();

    const fetchNewNotif = async () => {
        const response = await getNotif();
        setNewNotifications(response);
    }

    const fetchNotifHistory = async () => {
        const response = await getNotifHistory();
        setNotificationsHistory(response);
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchNotifHistory();
            await fetchNewNotif();
        };
        fetchData();
        setNotifCount(0);
    }, []);

    useEffect(() => {
        if (newRequestCount > 0) {
            fetchFollowData();
        }
    }, [newRequestCount]);

    const handleAccept = async (id) => {
        const response = await handleFollowAction(id, 'confirm');
        if (response.status === 200) {
            setNewNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
            fetchFollowData();
            addToast('Request accepted', 'success');
        } else {
            addToast('There was an error accepting the request, please try again later.', 'error');
        }
    }

    const handleDelete = async (id) => {
        const response = await handleFollowAction(id, 'delete');
        if (response.status === 200) {
            setNewNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
            fetchFollowData();
            addToast('Request deleted', 'success');
        } else {
            addToast('There was an error deleting the request, please try again later.', 'error');
        }
    }

    const renderLikeContent = (notification) => {
        if (notification.hasOwnProperty('note')) {
        return (
            <CustomLink
                to={`/n/${btoa(notification.note.id)}`}
                className='content-card'
            >
              <SlimNoteCard 
                title={notification.note.title}
                content={notification.note.note}
              />
            </CustomLink>
        );
        } else {
        return (
            <div className="notification-content resource">
              <SlimResourceCard 
                id={notification.resource.id}
                thumbnail={notification.resource.image_link}
                type={notification.resource.type}
                title={notification.resource.title}
                author={notification.resource.author}
                year={notification.resource.year}
              />
            </div>
        );
        }
    };

    const renderLikes = () => {
        return (
        <>
            <h3>New</h3>
            {newNotifications.map(notification => (
            <div key={notification.id} className="notification-item">
                <p style={{marginBottom:'10px', display:'flex', alignItems:'center', gap:'5px'}}>
                    <BiSolidLike size={18} color='#257953' />
                    <CustomLink
                      to={profilePage(notification.user.id, notification.user.first_name, notification.user.last_name)}
                      className='hover-to-primary-color'
                    >
                      {notification.user.first_name} {notification.user.last_name}
                    </CustomLink> 
                    liked {notification.hasOwnProperty('note') ? 'your note' : 'a resource in your library'}
                </p>
                {renderLikeContent(notification)}
                <span className="notification-date">{notification.date}</span>
            </div>
            ))}
            {newNotifications.length === 0 && (
                <div style={{marginLeft:'20px', marginTop:'20px'}}>
                    <p style={{fontSize:'1em', color:'#999'}}>No new notifications</p>
                </div>
            )}
            {notificationsHistory.length > 0 && (
                <>
                    <div className='rx-divider-vertical' style={{marginTop:'20px'}}></div>
                    <h3>Past 30 days</h3>
                    {notificationsHistory.map(notification => (
                    <div key={notification.id} className="notification-item">
                        <p style={{marginBottom:'10px', display:'flex', alignItems:'center', gap:'5px'}}>
                            <BiSolidLike size={18} color='#257953' />
                            <CustomLink
                              to={profilePage(notification.user.id, notification.user.first_name, notification.user.last_name)}
                              className='hover-to-primary-color'
                            >
                              {notification.user.first_name} {notification.user.last_name}
                            </CustomLink> 
                            liked {notification.hasOwnProperty('note') ? 'your note' : 'a resource in your library'}
                        </p>
                        {renderLikeContent(notification)}
                        <span className="notification-date">{notification.date}</span>
                    </div>
                    ))}
                </>
            )}
        </>
        );
    };

    const renderFollowRequests = () => {
        if (pendingRequests.length === 0) {
            return (
                <div style={{marginLeft:'20px', marginTop:'20px'}}>
                    <p style={{fontSize:'1em', color:'#999'}}>No follow requests</p>
                </div>
            );
        }
        return (
        <>
            {pendingRequests.map(request => (
            <div key={request.id} className="request-item">
                <p>
                    <CustomLink
                      to={profilePage(request.id, request.first_name, request.last_name)}
                      className='hover-to-green'
                    >
                      {request.first_name} {request.last_name}
                    </CustomLink> 
                    wants to follow you
                </p>
                <div className='action-buttons'>
                    <button className='linko-button linko-button--primary' onClick={() => handleAccept(request.id)}>Accept</button>
                    <button className='linko-button linko-button--cancel' onClick={() => handleDelete(request.id)}>Delete</button>
                </div>
            </div>
            ))}
        </>
        );
    };

    return (
        <div className="profile-page">
        <h1>Notifications</h1>
        <div className="tabs">
            <button
            className={activeTab === 'likes' ? 'active' : ''}
            onClick={() => setActiveTab('likes')}
            >
            LIKES
            </button>
            <button
            className={activeTab === 'followRequests' ? 'active' : ''}
            onClick={() => setActiveTab('followRequests')}
            style={{position:'relative'}}
            >
            FOLLOW REQUEST
            {pendingRequests.length > 0 && <span className="notification-dot"></span>}
            </button>
        </div>
        <div className="notifications-list">
            {activeTab === 'likes' && renderLikes()}
            {activeTab === 'followRequests' && renderFollowRequests()}
        </div>
        </div>
    );
};

export default NotificationPage;
