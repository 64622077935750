import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../Context/AppContext';
import CustomAvatar from '../CustomAvatar';
import NoteCard from '../NoteCard/NoteCard';
import ResourceCard from '../ResourceCard/ResourceCard';
import Loader from '../Loader';
import NoContentPlaceholder from './NoContentPlaceholder';
import { BiLike, BiSolidLike } from "react-icons/bi";
import { putLikeToFeed, profilePage, convertResourceType, fetchFollowingFeed } from '@linko/shared_utils';
import CustomLink from '../CustomLink';

const TabExplore = ({
    limit,
    explore,
    setExplore,
    exploreOffset,
    setExploreOffset,
    isFetchingMoreExplore,
    setIsFetchingMoreExplore,
    hasMoreExplore,
    setHasMoreExplore,
    resourceId,
    subjectId,
    type
}) => { 
    
    const shareView = true; 
    const location = useLocation();
    const resourcePage = location.pathname.startsWith('/r/');
    const subjectsPage = location.pathname.startsWith('/s/');
    const homePage = location.pathname.startsWith('/my_linko');
    const { userInfo, newFeedCount } = useContext(UserContext);

    useEffect(() => {
        if (resourcePage || subjectsPage) { 
            setExplore([]);
            setExploreOffset(0);
            setIsFetchingMoreExplore(true);
        }
    }, [resourcePage, subjectsPage]); 

    useEffect(() => {
        if (isFetchingMoreExplore) {
            fetchFollowingFeed(limit, exploreOffset, subjectId, resourceId, setExplore, setExploreOffset, setHasMoreExplore, setIsFetchingMoreExplore);
        }
    }, [isFetchingMoreExplore]);

    useEffect(() => {
        if (homePage && (explore.length === 0 || newFeedCount > 0)) { 
            setExplore([]);
            setExploreOffset(0);
            setIsFetchingMoreExplore(true);
        }
    }, [homePage, newFeedCount]); 

    explore = explore.map(item => {
        const liked = item.liked_by.some(user => user.id === userInfo.id);
        let ExploreMessage = '';
        if (!item.note) {
            const resourceType = convertResourceType(item.resource.type);
            if (resourcePage) {
                if (item.resource.id.toString() === resourceId.toString()) {
                    ExploreMessage = `added this ${type}`;
                } else {
                    ExploreMessage = `added a related ${resourceType}`;
                }
            } else if (subjectsPage) {
                ExploreMessage = `added a related ${resourceType}`;
            } else if (homePage) {
                if (resourceType === 'article' || resourceType === 'online course') {
                    ExploreMessage = `added an ${resourceType}`;
                } else {
                    ExploreMessage = `added a ${resourceType}`;
                }
            }
        } else {
            if (resourcePage) {
                ExploreMessage = `took a note on this ${type}`;
            } else {
                ExploreMessage = "took a note";
            }
        }
        return {
            ...item,
            liked,
            ExploreMessage
        }
    });

    const handleLike = async (id) => {
        const response = await putLikeToFeed(id);
        if (response.status === 200) {
            setTimeout(() => {
                setExplore(prevExplore => prevExplore.map(item => {
                    const itemId = id.startsWith('N') || id.startsWith('R') ? id.slice(1) : id;
                    if (item.id.toString() === itemId) {
                        return {
                            ...item,
                            liked: !item.liked, 
                            liked_by: item.liked 
                            ? item.liked_by.filter(user => user.id !== userInfo.id) 
                            : [...item.liked_by, userInfo]
                        };
                    }
                    return item; 
                }));
            }, 300); // 300ms delay, adjust as needed
        }
    }


    if (explore && explore.length === 0 && !isFetchingMoreExplore) {
        return (<NoContentPlaceholder activeTab={'explore'}/>);
    }

    return (
        <div className='explore-tab-content'>
            {explore && 
            <>
            {explore.map((item, index) => {
                const isNote = item.hasOwnProperty('note');
                const isFirstResource = !isNote && explore.slice(0, index).every(i => i.hasOwnProperty('note'));
                
                return (
                    <React.Fragment key={item.id}>
                        {isFirstResource && resourcePage && (
                            <div className="feed-resource-divider">Related resources</div>
                        )}
                        <div className="feed-group">
                            <div className='feed-group-title'>
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <CustomAvatar size={25} firstName={item.user.first_name} lastName={item.user.last_name}/>
                                    <div className='followee-name'>
                                        <CustomLink
                                            to={profilePage(item.user.id, item.user.first_name, item.user.last_name)}
                                        >
                                            {item.user.first_name} 
                                        </CustomLink>
                                    </div>
                                    <p className='feed-action-detail'>{item.ExploreMessage}</p>
                                </div>
                                {item.liked ? 
                                    <BiSolidLike 
                                        className={`like-icon liked ${item.liked ? 'animate-like' : ''}`} 
                                        style={{color:'#257953'}} 
                                        onClick={() => handleLike(isNote ? `N${item.id}` : `R${item.id}`)}
                                    />
                                    :
                                    <BiLike 
                                        className='like-icon' 
                                        onClick={() => handleLike(isNote ? `N${item.id}` : `R${item.id}`)}
                                    />
                                }
                            </div>
                            {isNote ? 
                                <div className='cards-container'>
                                    <NoteCard 
                                        key={`N${item.id}`}
                                        note={item} 
                                        shareView={shareView} 
                                    />
                                </div>
                                :
                                <div className='cards-container'>
                                    <ResourceCard 
                                        key={`R${item.id}`}
                                        resource={item} 
                                        shareView={shareView} 
                                        style={{marginRight:'20px'}}
                                    />
                                </div>
                            }
                        </div>
                    </React.Fragment>
                );
            })}
            </>
            }
            {hasMoreExplore && isFetchingMoreExplore ? 
                <div className='tab-content-loader'>
                    <Loader/>
                </div>
                :
                <div style={{height:'10px'}}></div>
            }
            {!isFetchingMoreExplore && explore.length === 0 &&
                    <p className='no-content-placeholder' >No content yet</p>
            }
            {!hasMoreExplore && explore.length >= 10 ? 
                <p style={{paddingLeft:'24px', fontStyle:'italic', margin:'30px 0'}}>No more content</p>
                :
                <div style={{height:'30px'}}></div>
            }
        </div>
    );
};

export default TabExplore;
