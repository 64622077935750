import React, {useState, useEffect, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { fetchResources, fetchBestUnlearned } from '@linko/shared_utils';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';

const RecentlyAdded = (
    {
        subjectId,
        topRelatedSubjects,
        userRecentResources,
        userCommonResources,
        userName
    }
) => {

    const [inProgressResources, setInProgressResources] = useState([]);
    const [finishedResources, setFinishedResources] = useState([]);
    const [topUnlearnedResources, setTopUnlearnedResources] = useState([]);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();

    useEffect(() => {
        const fetchUserResources = async () => {
            try {
                // Fetch in-progress resources
                const inProgressResults = await fetchResources(
                    3, 
                    0, 
                    [], 
                    'in_progress', 
                    [], 
                    [], 
                    setInProgressResources,
                    () => {}, 
                    () => {}, 
                    () => {}, 
                    null 
                );

                // Fetch finished resources
                const finishedResults = await fetchResources(
                    3, 
                    0, 
                    [], 
                    'finished', 
                    [], 
                    [], 
                    setFinishedResources,
                    () => {}, 
                    () => {}, 
                    () => {}, 
                    null 
                );
            } catch (error) {
                console.error('Error fetching resources:', error);
                setPersistentMessage('Failed to load resources', 'error');
            }
        };

        const fetchSubjectTopResources = async () => {
            if (topRelatedSubjects && topRelatedSubjects.length > 0) {
                const response = await fetchBestUnlearned(topRelatedSubjects);
                const seenIds = new Set();
                const aggregatedResources = [];
                
                topRelatedSubjects.forEach(id => {
                    const subjectResources = response.data[id] || [];
                    subjectResources.forEach(resource => {
                        if (!seenIds.has(resource.id)) {
                            seenIds.add(resource.id);
                            aggregatedResources.push(resource);
                        }
                    });
                });
                setTopUnlearnedResources(aggregatedResources);
            } else if (subjectId) {
                const response = await fetchBestUnlearned([subjectId]);
                if (response.data.detail === "No unlearned resources found for the given subject.") {
                    setTopUnlearnedResources([]);
                } else {
                    const resourcesForSingleSubject = response.data[subjectId] || [];
                    setTopUnlearnedResources(resourcesForSingleSubject);   
                }
            } 
        }
        if (location.pathname.includes('/my_linko')) {
            fetchUserResources();
        } else if (location.pathname.includes('/s/')) {
            fetchSubjectTopResources();
        }
    }, [location.pathname]);

    const renderResourceSection = (resources, title) => (
        resources?.length > 0 && (
            <>
                <div className={`subject-rel-title ${title === 'Completed' ? 'mt-20' : ''}`}>{title}</div>
                <div className='recently-added-resources mt-10'>
                    {resources.length > 0 && resources.map((resource) => {
                        // Handle both resource structures
                        const resourceData = resource.resource || resource;
                        return (
                            <SlimResourceCard   
                                key={resourceData.id}
                                id={resourceData.id}
                                thumbnail={resourceData.image_link}  
                                type={resourceData.type}
                                title={resourceData.title}
                                author={resourceData.author}
                                year={resourceData.year}
                                onResourceClick={() => safeNavigate(`/r/${resourceData.id}`)}
                            />
                        );
                    })}
                </div>
            </>
        )
    );

    return (
        <div className='recently-added'>
            {renderResourceSection(inProgressResources, 'Currently Learning')}
            {renderResourceSection(finishedResources, 'Completed')}
            {renderResourceSection(topUnlearnedResources, 'Recommended')}
            {renderResourceSection(userRecentResources, `${userName}'s Learned`)}
            {renderResourceSection(userCommonResources, 'We Both Learned')}
        </div>
    );
};

export default RecentlyAdded;