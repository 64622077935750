import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const getSingleNote = async (noteId) => {
    try {
        const headers = {};
        const accessToken = await storageUtils.getItem('access_token');
        
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        const response = await axios.get(`/api/note/${noteId}/`, { headers });
        return response;
    } catch (error) {
        console.error('Error fetching single note:', error);
        throw error;
    }
};