import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils';

export const searchKnowledge = async (keyword) => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }

    const response = await axios.get('/api/search/search_knowledge/', {
      params: { keyword },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error searching knowledge:', error);
    throw error;
  }
};