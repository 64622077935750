import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${storageUtils.getItem('access_token')}`,
  },
});

export const fetchUserFollow = async (user_id) => {
  let response;
  const accessToken = await storageUtils.getItem('access_token');
  const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
  if (user_id) {
    response = await axios.get(`/api/user/get_follow_data/?user_id=${user_id}`, { headers });
  } else {
    response = await axios.get('/api/user/get_follow_data/', { headers });
  }
  return response.data;
};

export const sendFollowRequest = async (friendId) => {
  const response = await axios.post('/api/user/send_follow_request/', { friend_id: friendId }, getAuthHeaders());
  return response;
};

export const acceptFollowRequest = async (friendId, action) => {
  return handleFollowAction(friendId, action, 'post');
};

export const handleFollowAction = async (friendId, action) => {
  // Actions can be 'confirm', 'delete', 'unfollow', 'remove follower'
  const response = await axios.put('/api/user/handle_follow_action/', 
    { friend_id: friendId, action }, 
    getAuthHeaders()
  );
  return response;
};
