export const parseUrl = (url) => {
    let urlObject;
    try {
        urlObject = new URL(url);
    } catch (_) {
        return { isYouTube: false, videoId: null, isPDF: false, isBook: false, bookId: null };
    }

    const hostname = urlObject.hostname;
    const pathname = urlObject.pathname;

    const isYouTube = hostname.includes('youtube.com') || hostname.includes('youtu.be');
    const isPDF = pathname.endsWith('.pdf') || pathname.includes('/pdf/') || hostname.includes('arxiv.org');

    let videoId = null;
    let bookId = null;

    if (isYouTube) {
        if (url.includes('youtube')) {
            const urlParams = new URLSearchParams(urlObject.search);
            videoId = urlParams.get('v');
            const ampersandPosition = videoId ? videoId.indexOf('&') : -1;
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
        } else if (url.includes('youtu.be')) {
            videoId = url.split('/')[3].split('?')[0];
        }
    }

    return { isYouTube, videoId, isPDF };
};

export const loadBookPreview = (bookId, setLoaded, setOmitPreview) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://www.google.com/books/jsapi.js';
    scriptTag.onload = () => {
        if (window.google && window.google.books) {
            window.google.books.load();
            const checkInterval = setInterval(() => {
                if (window.google.books.DefaultViewer) {
                    clearInterval(checkInterval);
                }
            }, 100);
        } else {
            setLoaded(false);
            setOmitPreview(true);
        }
    };
    scriptTag.onerror = (e) => {
        setLoaded(false); 
        setOmitPreview(true);
    };
    document.body.appendChild(scriptTag);

    fetch(`https://www.googleapis.com/books/v1/volumes/${bookId}`)
    .then(response => response.json())
    .then(data => {
        if (data.accessInfo && data.accessInfo.embeddable === false) {
            setOmitPreview(true);
        } else if (data.accessInfo && data.accessInfo.embeddable === true) {
            setLoaded(true);
        }
    })
    .catch(error => {
        setOmitPreview(true);
    });

    return () => {
        document.body.removeChild(scriptTag);
    };
};