import React, { useState, useEffect } from 'react';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import ResourceThumbnailPlaceholder from '../AddModals/ResourceThumbnailPlaceholder';
import { cleanAuthorName, convertResourceType } from '@linko/shared_utils';
import CustomLink from '../CustomLink';

const SlimResourceCard = ({ 
    id, thumbnail, type, title, author, year, onResourceClick, onAddToLibrary 
}) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouch);
  }, []);

  return (
    <div className='slim-resource-card'>
      <div className='slim-resource-card-content'>

        {thumbnail ? 
          <div style={{ width: '50px', height: '80px', flexShrink: 0, borderRadius: '4px', overflow: 'hidden' }}>
            <img 
              src={thumbnail} 
              alt={title} 
              style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover'
              }} 
              onClick={onResourceClick}
            />
          </div>
        : <ResourceThumbnailPlaceholder type={type} width={"50px"} height={"80px"} onClick={onResourceClick}/>
        }
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', minHeight: '80px' }}>
          <div>
            <p style={{
              fontSize: '12px',
              color: '#999999'
            }}>
              {convertResourceType(type).toUpperCase()}
            </p>
            {id === null ? (
              <p className='slim-resource-card-title' onClick={onResourceClick}>
                {title}
              </p>
            ) : (
              <CustomLink
                to={`/r/${id}`}
                className='slim-resource-card-title'
              >
                {title}
              </CustomLink>
            )}
          </div>
          <div style={{ 
            fontSize: '12px', 
            color: '#999999',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            lineHeight: '1.2em',
            maxHeight: '2.4em'
          }}>
            {`${author ? cleanAuthorName(author) : 'Unknown Author'}${year ? `, ${year}` : ''}`}
          </div>
        </div>
      </div>
      {onAddToLibrary && (
        <Tippy 
          content={'Add to my library'}
          placement="left"
          arrow={true}
          theme='light-border'
          touch={false}
          disabled={isTouchDevice}
        >
          <div 
            className='add-resource-icon' 
            onClick={(e) => {
              e.stopPropagation(); 
              onAddToLibrary();
            }}  
          >
            <MdOutlineLibraryAdd size={22} style={{ marginTop: '3px' }} />
          </div>
        </Tippy>
      )}
    </div>
  );
};

export default SlimResourceCard;
