import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TabNotes from '../../Components/ContentTabs/TabNotes';
import TabResources from '../../Components/ContentTabs/TabResources';
import LibraryFilter from './LibraryFilter';
import LibrarySubjectFilter from './LibrarySubjectFilter';
import { handleScrollEvent, setupScrollListeners } from '../../Components/ContentTabs/handleScroll';
import { fetchNotes, fetchResources } from '@linko/shared_utils';
import { PiNotePencilBold } from "react-icons/pi";
import { RiBook2Line } from "react-icons/ri";

const LibraryTabs = ({
    userSubject,
    userCounts,
}) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { activeTab } = useParams();

    // Parse URL path to get filters
    const parseUrlFilters = () => {
        const pathParts = location.pathname.split('/').filter(Boolean);
        const startIndex = pathParts.indexOf(activeTab) + 1;
        if (startIndex >= pathParts.length) return {};
        
        if (activeTab === 'resources') {
            const types = pathParts[startIndex]?.split(',') || [];
            const subjects = types.filter(id => !isNaN(parseInt(id, 10))); // Extract subject IDs
            const resourceTypes = types.filter(type => ['bo', 'vi', 'ar', 'po', 'oc', 'or'].includes(type)); // Extract resource types
            const finished = pathParts[startIndex + 1] || null;

            return { 
                types: resourceTypes, 
                subjects: subjects,
                finished: ['not_started', 'in_progress', 'finished'].includes(finished) ? finished : null 
            };
        } else {
            const subjects = pathParts[startIndex]?.split(',').map(decodeURIComponent) || [];
            return { subjects };
        }
    };

    // Initialize state from URL
    const urlFilters = parseUrlFilters();
    const [filterType, setFilterType] = useState(urlFilters.types || []);
    const [filterFinished, setFilterFinished] = useState(urlFilters.finished);
    const [resourceFilterSubject, setResourceFilterSubject] = useState(urlFilters.subjects || []);
    const [noteFilterSubject, setNoteFilterSubject] = useState(urlFilters.subjects || []);

    const limit = 30;

    const [notes, setNotes] = useState([]);
    const [notesOffset, setNotesOffset] = useState(0);
    const [isFetchingMoreNotes, setIsFetchingMoreNotes] = useState(false);
    const [hasMoreNotes, setHasMoreNotes] = useState(true);

    const [resources, setResources] = useState([]);
    const [resourcesOffset, setResourcesOffset] = useState(0);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(false);
    const [hasMoreResources, setHasMoreResources] = useState(true);

    // Add abort controller ref
    const abortControllerRef = useRef(null);

    // Effect to trigger initial fetch
    useEffect(() => {
        if (activeTab === 'resources') {
            setIsFetchingMoreResources(true);
        } else if (activeTab === 'notes') {
            setIsFetchingMoreNotes(true);
        }
    }, [activeTab]);

    // Effect to handle filter changes for resources
    useEffect(() => {
        if (activeTab === 'resources') {
            // Abort any ongoing requests
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            // Reset state
            setResources([]);
            setResourcesOffset(0);
            setHasMoreResources(true);
            setIsFetchingMoreResources(true);
        }
    }, [filterType, filterFinished, resourceFilterSubject, activeTab]);

    // Effect to handle filter changes for notes
    useEffect(() => {
        if (activeTab === 'notes') {
            // Abort any ongoing requests
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            // Reset state
            setNotes([]);
            setNotesOffset(0);
            setHasMoreNotes(true);
            setIsFetchingMoreNotes(true);
        }
    }, [noteFilterSubject, activeTab]);

    // Effect to fetch data when isFetchingMore is true or filters change
    useEffect(() => {
        const fetchData = async () => {
            // Abort previous request if it exists
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            // Create new abort controller for this request
            abortControllerRef.current = new AbortController();

            if (activeTab === 'resources' && isFetchingMoreResources) {
                try {
                    const validFilterType = filterType.filter(type => 
                        ['bo', 'vi', 'ar', 'po', 'oc', 'or'].includes(type)
                    );
                    const validSubjects = resourceFilterSubject.filter(subject => 
                        !isNaN(parseInt(subject, 10))
                    );
                    const validFinished = ['not_started', 'in_progress', 'finished'].includes(filterFinished) 
                        ? filterFinished 
                        : null;

                    await fetchResources(
                        limit,
                        resourcesOffset,
                        validFilterType,
                        validFinished,
                        validSubjects,
                        [],
                        setResources,
                        setResourcesOffset,
                        setHasMoreResources,
                        setIsFetchingMoreResources,
                        abortControllerRef.current.signal // Pass the signal to fetch function
                    );
                } catch (error) {
                    if (error.name === 'AbortError') {
                        // Handle abort silently
                        return;
                    }
                    // Handle other errors
                    console.error(error);
                } finally {
                    setIsFetchingMoreResources(false);
                }
            } else if (activeTab === 'notes' && isFetchingMoreNotes) {
                try {
                    const validSubjects = noteFilterSubject.filter(subject => 
                        !isNaN(parseInt(subject, 10))
                    );

                    await fetchNotes(
                        limit,
                        notesOffset,
                        null,
                        null,
                        [],
                        setNotes,
                        setNotesOffset,
                        setHasMoreNotes,
                        setIsFetchingMoreNotes,
                        validSubjects,
                        abortControllerRef.current.signal // Pass the signal to fetch function
                    );
                } catch (error) {
                    if (error.name === 'AbortError') {
                        // Handle abort silently
                        return;
                    }
                    // Handle other errors
                    console.error(error);
                } finally {
                    setIsFetchingMoreNotes(false);
                }
            }
        };

        // Only fetch if fetching flag is true
        if (isFetchingMoreResources || isFetchingMoreNotes) {
            fetchData();
        }

        // Cleanup function to abort any pending requests when component unmounts
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [
        isFetchingMoreResources,
        isFetchingMoreNotes,
        activeTab,
        filterType,
        filterFinished,
        resourceFilterSubject,
        noteFilterSubject,
        notesOffset,
        resourcesOffset
    ]);

    // Effect to update URL
    useEffect(() => {
        if (activeTab === 'resources') {
            const allFilters = [...filterType, ...resourceFilterSubject].filter(Boolean);
            const filterString = allFilters.length ? allFilters.join(',') : '';
            const finished = filterFinished || '';
            
            const newPath = `/my_library/resources${filterString ? `/${filterString}` : ''}${finished ? `/${finished}` : ''}`;
            if (location.pathname !== newPath) {
                navigate(newPath, { replace: true });
            }
        } else if (activeTab === 'notes') {
            const subjects = noteFilterSubject.length ? noteFilterSubject.map(encodeURIComponent).join(',') : '';
            const newPath = `/my_library/notes${subjects ? `/${subjects}` : ''}`;
            if (location.pathname !== newPath) {
                navigate(newPath, { replace: true });
            }
        }
    }, [filterType, filterFinished, resourceFilterSubject, noteFilterSubject, activeTab, location.pathname, navigate]);

    // Scroll handler
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.innerHeight + window.pageYOffset;
            const documentHeight = document.documentElement.scrollHeight;

            if (documentHeight - scrollPosition < 300) {
                if (activeTab === 'resources' && hasMoreResources && !isFetchingMoreResources) {
                    setIsFetchingMoreResources(true);
                } else if (activeTab === 'notes' && hasMoreNotes && !isFetchingMoreNotes) {
                    setIsFetchingMoreNotes(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeTab, hasMoreResources, hasMoreNotes, isFetchingMoreResources, isFetchingMoreNotes]);

    const handleTabChange = (tab) => {
        // Return early if we're currently fetching data
        if ((tab === 'resources' && isFetchingMoreResources) || 
            (tab === 'notes' && isFetchingMoreNotes)) {
            return;
        }

        if (tab === 'resources') {
            setFilterType([]);
            setFilterFinished(null);
            setResourceFilterSubject([]);
            setResourcesOffset(0);
            setResources([]);
            setHasMoreResources(true);
            setIsFetchingMoreResources(true);
        } else if (tab === 'notes') {
            setNoteFilterSubject([]);
            setNotesOffset(0);
            setNotes([]);
            setHasMoreNotes(true);
            setIsFetchingMoreNotes(true);
        }
        navigate(`/my_library/${tab}`);
    };

    // Infinite scroll effect
    useEffect(() => {
        const cleanupScrollListeners = setupScrollListeners(
            handleScrollEvent,
            activeTab,
            setIsFetchingMoreNotes,
            setIsFetchingMoreResources,
            hasMoreNotes,
            hasMoreResources,
        );

        return () => {
            cleanupScrollListeners();
        };
    }, [activeTab, hasMoreNotes, hasMoreResources]);

    // Navbar scroll effect
    useEffect(() => {
        let lastScrollTop = 0;
        const handleScroll = () => {
            const navbar = document.querySelector('.library-tabs-wrapper');
            const currentScroll = window.scrollY || document.documentElement.scrollTop;
            if (navbar) {
                if (currentScroll > lastScrollTop) {
                    navbar.style.top = '0px';
                } else {
                    navbar.style.top = '70px';
                }
                lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const updateNoteFilterSubject = (newSubjects) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        
        setNoteFilterSubject(newSubjects);
        setNotesOffset(0);
        setNotes([]);
        setHasMoreNotes(true);
        setIsFetchingMoreNotes(true);
    };

    const updateResourceFilters = (newSubjects, newTypes, newFinished) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        
        setResourceFilterSubject(newSubjects);
        setFilterType(newTypes);
        setFilterFinished(newFinished);
        setResourcesOffset(0);
        setResources([]);
        setHasMoreResources(true);
        setIsFetchingMoreResources(true);
    };

    return (
        <div className='library-tabs'>
            <div className='library-tabs-wrapper' style={{ marginBottom: activeTab === 'notes' ? '10px' : '0px' }}>
                <div className='tabs' style={{columnGap: '15px'}}>
                    <button
                        onClick={() => handleTabChange('resources')}
                        className={'tab-button' + (activeTab === 'resources' ? ' active' : '')}
                        disabled={isFetchingMoreNotes}
                    >
                        <RiBook2Line/>
                        &nbsp;
                        Resources
                    </button>
                    <button
                        onClick={() => handleTabChange('notes')}
                        className={'tab-button' + (activeTab === 'notes' ? ' active' : '')}
                        disabled={isFetchingMoreResources}
                    >
                        <PiNotePencilBold/>
                        &nbsp;
                        Notes
                    </button>
                </div>
            </div>
            <div className='tab-content'>
                {activeTab === 'notes' && (
                    <>
                        <LibrarySubjectFilter
                            filterSubject={noteFilterSubject}
                            setFilterSubject={updateNoteFilterSubject}
                            activeTab={activeTab}
                            isLoading={isFetchingMoreNotes}
                            userSubject={userSubject}
                        />
                        <TabNotes
                            notes={notes}
                            setNotes={setNotes}
                            isFetchingMoreNotes={isFetchingMoreNotes}
                            hasMoreNotes={hasMoreNotes}
                        />
                    </>
                )}
                {activeTab === 'resources' && (
                    <>
                        <div className='filter-button-wrapper'>
                            <LibraryFilter
                                filterType={filterType}
                                setFilterType={(newTypes) => updateResourceFilters(resourceFilterSubject, newTypes, filterFinished)}
                                filterFinished={filterFinished}
                                setFilterFinished={(newFinished) => updateResourceFilters(resourceFilterSubject, filterType, newFinished)}
                                isLoading={isFetchingMoreResources}
                                userCounts={userCounts}
                            />
                        </div>
                        <LibrarySubjectFilter
                            filterSubject={resourceFilterSubject}
                            setFilterSubject={(newSubjects) => updateResourceFilters(newSubjects, filterType, filterFinished)}
                            activeTab={activeTab}
                            isLoading={isFetchingMoreResources}
                            userSubject={userSubject}
                        />
                        <TabResources
                            resources={resources}
                            setResources={setResources}
                            isFetchingMoreResources={isFetchingMoreResources}
                            hasMoreResources={hasMoreResources}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default LibraryTabs;