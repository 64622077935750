import React from 'react';
import { RiBook2Line } from "react-icons/ri";
import { LuGraduationCap } from "react-icons/lu";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { LuHeadphones } from "react-icons/lu";
import { FaRegNewspaper } from "react-icons/fa6";
import { GrArticle } from "react-icons/gr";
import { PiGlobe} from "react-icons/pi";

const ResourceTypeIcon = ({ type, size }) => {
    
    let icon;
    if (type === "bo") {
        icon = <RiBook2Line size={size} />;
    } else if (type === "oc") {
        icon = <LuGraduationCap size={size} />;
    } else if (type === "vi"){
        icon = <MdOutlineOndemandVideo size={size} />;
    } else if (type === "po"){
        icon = <LuHeadphones size={size} />;
    } else if (type === "ar"){
        icon = <GrArticle size={size} />;
    } else if (type === "or"){
        icon = <PiGlobe size={size} />;
    }

    return (
        <div className='resource-type-icon'>
            {icon}
        </div>
    );
};

export default ResourceTypeIcon;