import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { subjectPage } from '@linko/shared_utils';
import CustomLink from '../../Components/CustomLink';

const SubjectRel = ({ broaderField, siblingFields, childFields }) => {
    const [showAllSibling, setShowAllSibling] = useState(false);
    const [showAllChild, setShowAllChild] = useState(false);
    const sortedChildFields = childFields.sort((a, b) => b.learn_count - a.learn_count);
    const sortedSiblingFields = siblingFields.sort((a, b) => b.learn_count - a.learn_count);

    return (
        <div className='subject-rel'>
            <div className='subject-rel-title'>Knowledge Tree</div>
            {broaderField && broaderField.length > 0 && 
            <>
                <h3>BROADER FIELD</h3>
                <div className='subject-rel-list'>
                    {broaderField.map((field, index) => (
                        <div 
                            key={index} 
                            className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`}
                        >
                            <CustomLink
                                to={subjectPage(field.name, false)}
                            >
                                {field.name}
                                {field.learn_count > 0 && ` · ${field.learn_count}`}
                            </CustomLink>
                        </div>
                    ))}
                </div>
            </>
            }
            {sortedSiblingFields && sortedSiblingFields.length > 0 && 
            <>
                <h3>IN THE SAME FIELD</h3>
                <div className='subject-rel-list'>
                    {sortedSiblingFields.slice(0, showAllSibling ? sortedSiblingFields.length : 5).map((field, index) => (
                        <div 
                            key={index} 
                            className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`}
                        >
                            <CustomLink
                                to={subjectPage(field.name, false)}
                            >
                                {field.name}
                                {field.learn_count > 0 && ` · ${field.learn_count}`}
                            </CustomLink>
                        </div>
                    ))}
                    {sortedSiblingFields.length > 5 && (
                        showAllSibling ? 
                            <button className='show-button' onClick={() => setShowAllSibling(false)}>
                                Show less <FaAngleUp style={{marginLeft:'5px', marginTop:'2px'}}/>
                            </button>
                            :
                            <button className='show-button' onClick={() => setShowAllSibling(true)}>
                                Show more <FaAngleDown style={{marginLeft:'5px', marginTop:'2px'}}/>
                            </button>
                    )}
                </div>
            </>
            }
            {sortedChildFields && sortedChildFields.length > 0 &&
            <>
                <h3>SUBFIELDS</h3>
                <div className='subject-rel-list'>
                    {sortedChildFields.slice(0, showAllChild ? sortedChildFields.length : 5).map((field, index) => (
                        <div 
                            key={index} 
                            className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`}
                        >
                            <CustomLink
                                to={subjectPage(field.name, false)}
                            >
                                {field.name}
                                {field.learn_count > 0 && ` · ${field.learn_count}`}
                            </CustomLink>
                        </div>
                    ))}
                    {sortedChildFields.length > 5 && (
                        showAllChild ? 
                            <button className='show-button' onClick={() => setShowAllChild(false)}>
                                Show less <FaAngleUp style={{marginLeft:'5px', marginTop:'2px'}}/>
                            </button>
                            :
                            <button className='show-button' onClick={() => setShowAllChild(true)}>
                                Show more <FaAngleDown style={{marginLeft:'5px', marginTop:'2px'}}/>
                            </button>
                    )}
                </div>
            </>
            }
        </div>
    );
};

export default SubjectRel;
