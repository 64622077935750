import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchSingleResource = async (resourceId) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        if (!accessToken) {
            throw new Error('No access token found');
        }

        const response = await axios.get(`/api/resource/${resourceId}/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return {
            resource: response.data.data,
            learnedUsers: response.data.learned_user_data,
            status: response.status,
            articleContent: response.data.preview_article,
            bookEmbeddable: response.data.preview,
            authorInfo: response.data.about_author,
        };
    } catch (error) {
        console.error('Error fetching single resource:', error);
        throw error;
    }
}