import React, {useContext} from "react";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { useLocation } from "react-router-dom";
import CustomLink from "../CustomLink";

function Footer() {
    const location = useLocation();
    const paths = ['/my_linko', '/s/', '/r/', '/profile/', '/my_library'];
    const hideFooter = paths.some(path => location.pathname.startsWith(path));    
    
    if (hideFooter) {
        return null;
    }
    return (
        <div id="footer" className={location.pathname === '/' ? 'landing-page-footer' : null}>
            <CustomLink to="https://linko.featurebase.app/" className='footer-link'>Feedback</CustomLink>
            <CustomLink to="https://linko.featurebase.app/changelog" className='footer-link'>Changelog</CustomLink>
            <CustomLink to="/term_of_service" className='footer-link'>Terms</CustomLink>
            <CustomLink to="/privacy_policy" className='footer-link'>Privacy</CustomLink>
        </div>
    );
}

export default Footer;
