import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchUserSubject = async (user_id) => {
  try {
    let response;
    const accessToken = await storageUtils.getItem('access_token');
    const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    
    if (user_id) {
      response = await axios.get(`/api/subject/?user_id=${user_id}`, { headers });
    } else {
      response = await axios.get('/api/subject/', { headers });
    }

    return {
      userSubject: response.data.subjects,
      userCounts: response.data,
    };
  } catch (error) {
    console.error('Error fetching user subject:', error);
    throw error;
  }
};


export const fetchRelatedSubjects = async (subjectId) => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.get(`/api/subject/related/?subject_id=${subjectId}`, { headers });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching related subjects:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    throw error;
  }
};
