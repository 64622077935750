import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';
import BackButton from '../../Components/BackButton';
import TheTabs from '../../Components/ContentTabs/TheTabs';
import SubjectPageHeader from './SubjectPageHeader';
import SubjectDescription from './SubjectDescription';
import SubjectRel from './SubjectRel';
import LeaderBoard from './LeaderBoard';
import RecentlyAdded from '../HomePage/RecentlyAdded';
import HalfFooter from '../../Components/NavAndFooter/HalfFooter';
import { fetchSubjectId, useWindowSize, fetchRelatedSubjects } from '@linko/shared_utils';
import WholeScreenModal from '../../Components/WholeScreenModal';
import Loader from '../../Components/Loader';
import CustomTagRel from './CustomTagRel';
const SubjectPage = () => {

  const {subjectName} = useParams();
  const location = useLocation();
  const [subjectId, setSubjectId] = useState(null);
  const [description, setDescription] = useState('');
  const [broaderField, setBroaderField] = useState([]);
  const [siblingFields, setSiblingFields] = useState([]);
  const [childFields, setChildFields] = useState([]);
  const [publicField, setPublicField] = useState(null);

  const [relatedSubjects, setRelatedSubjects] = useState([]);
  const [topRelatedSubjects, setTopRelatedSubjects] = useState([]);

  const [noteCount, setNoteCount] = useState(null);
  const [resourceCount, setResourceCount] = useState(null);
  const [topUsers, setTopUsers] = useState([]);
  const [notes, setNotes] = useState([]);
  const [library, setLibrary] = useState([]);
  const [editTag, setEditTag] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width } = useWindowSize(); 

  const fetchSubjectInfo = useCallback(async () => {
    setLibrary([]); 
    const isMyTag = location.pathname.includes('/my/');
    try {
      const subjectInfo = await fetchSubjectId(subjectName, isMyTag);
      setSubjectId(subjectInfo.subjectId);
      setDescription(subjectInfo.description);
      setPublicField(subjectInfo.publicField);
      setBroaderField(subjectInfo.broaderField);
      setSiblingFields(subjectInfo.siblingFields);
      setChildFields(subjectInfo.childFields);
      setNoteCount(subjectInfo.noteCount);
      setResourceCount(subjectInfo.resourceCount);
      setTopUsers(subjectInfo.topUsers);
    } catch (error) {
      console.error('Error fetching subject info:', error);
    }
  }, [subjectName, location.pathname]);

  useEffect(() => {
    setSubjectId(null);
    setDescription('');
    setPublicField(null);
    setBroaderField([]);
    setSiblingFields([]);
    setChildFields([]);
    setNoteCount(null);
    setResourceCount(null);
    setTopUsers([]);
    setLibrary([]); 
    fetchSubjectInfo();
  }, [subjectName, fetchSubjectInfo]);

  useEffect(() => {
    const fetchSubjects = async () => {
        try {
            const response = await fetchRelatedSubjects(subjectId);
            setRelatedSubjects(response);
            const topFiveIds = response.slice(0, 5).map(subject => subject.id);
            setTopRelatedSubjects(topFiveIds);
        } catch (err) {
            console.error('Error fetching related subjects:', err);
        }
    };
    if (subjectId && !publicField) {
      fetchSubjects();
    }
  }, [subjectId, publicField]);

  useEffect(() => {
    setShowTextArea(false);
  }, [location.pathname]);

  if (!subjectId) return (
    <div className='page-loading'>
      <Loader />
    </div>
  );

  return (
    <div className='page-container subject-page'>
      <div className='page-right'>
        <BackButton />
        <SubjectPageHeader
          editTag={editTag}
          setEditTag={setEditTag}
          subjectName={subjectName}
          publicField={publicField}
          subjectId={subjectId}
          description={description}
          setDescription={setDescription}
        />
        {!editTag && 
        <>
          <SubjectDescription description={description} noteCount={noteCount} resourceCount={resourceCount}/>
          {width <= 1089 && (publicField || relatedSubjects.length > 0) &&
            <div className='book-tabs' style={{margin:'20px'}}>
              <button className='tab-button' onClick={() => setIsModalOpen(true)}>
                Overview
              </button>
            </div>
          }
          <AddNoteButton 
            subjectId={subjectId} 
            noteHint={'Take a note under this topic'} 
            showTextArea={showTextArea} 
            setShowTextArea={setShowTextArea} 
            setNotes={setNotes} 
            setLibrary={setLibrary} 
          />
        </>}     
        <TheTabs 
          notes={notes}
          setNotes={setNotes}
          library={library}
          setLibrary={setLibrary}
          subjectId={subjectId} 
          publicField={publicField}
        />
      </div>
      
      {publicField ? 
        <div className='home-page-right'>
          <SubjectRel 
            broaderField={broaderField}
            siblingFields={siblingFields} 
            childFields={childFields} 
          />
          <RecentlyAdded 
            subjectId={subjectId} 
            publicField={publicField}
          />
          <LeaderBoard topUsers={topUsers} />
          <HalfFooter />
      </div>
      : relatedSubjects.length > 0 &&
        <div className='home-page-right'>
          <CustomTagRel relatedSubjects={relatedSubjects}/>
          <RecentlyAdded topRelatedSubjects={topRelatedSubjects}/>
          <HalfFooter />
        </div>
      }  
      <WholeScreenModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div style={{width:'380px'}}>
          {publicField ?
          <>
            <SubjectRel 
              broaderField={broaderField}
              siblingFields={siblingFields} 
              childFields={childFields} 
            />
            <RecentlyAdded subjectId={subjectId} />
            <LeaderBoard topUsers={topUsers} />
          </>
          :
          <>
            <CustomTagRel relatedSubjects={relatedSubjects} />
            <RecentlyAdded topRelatedSubjects={topRelatedSubjects}/>
          </>
          }
        </div>
      </WholeScreenModal>
    </div>
  );
};

export default SubjectPage;
