import { useCallback, useRef, useEffect } from 'react';

export const useScrollToEnd = (onEndReached, threshold = 0.8) => {
  const onEndReachedCalledDuringMomentum = useRef(false);
  const isScrolling = useRef(false);

  const onScroll = useCallback(
    (event) => {
      if (!event.nativeEvent) return;

      const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
      const paddingToBottom = 20;
      const isCloseToBottom =
        layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom;

      if (isCloseToBottom && !onEndReachedCalledDuringMomentum.current && !isScrolling.current) {
        onEndReached();
        onEndReachedCalledDuringMomentum.current = true;
      }
    },
    [onEndReached]
  );

  const onMomentumScrollBegin = () => {
    onEndReachedCalledDuringMomentum.current = false;
    isScrolling.current = true;
  };

  const onMomentumScrollEnd = () => {
    isScrolling.current = false;
  };

  const onScrollForWeb = useCallback(
    (event) => {
      if (!event.target) return;

      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const isCloseToBottom = scrollTop + clientHeight >= scrollHeight * threshold;

      if (isCloseToBottom && !onEndReachedCalledDuringMomentum.current && !isScrolling.current) {
        onEndReached();
        onEndReachedCalledDuringMomentum.current = true;
      }
    },
    [onEndReached, threshold]
  );

  useEffect(() => {
    return () => {
      onEndReachedCalledDuringMomentum.current = false;
      isScrolling.current = false;
    };
  }, []);

  return { onScroll, onMomentumScrollBegin, onMomentumScrollEnd, onScrollForWeb };
};
