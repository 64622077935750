import axios from '../Middleware/axios.js';
import storageUtils from '../storageUtils.js';

export const fetchSubjectId = async (subjectName, myTag) => {
  try {
    const accessToken = storageUtils.getItem('access_token');
    const headers = { Authorization: `Bearer ${accessToken}` };
    const query = myTag ? `?custom=${subjectName}` : `?name=${subjectName}`;
    const response = await axios.get(`/api/subject/details/${query}`, { headers });
    return {
      subjectId: response.data.data.id,
      description: response.data.data.description,
      publicField: response.data.data.is_linked,
      broaderField: response.data.data.father,
      siblingFields: response.data.data.siblings,
      childFields: response.data.data.children,
      noteCount: response.data.notes_count,
      resourceCount: response.data.resource_count,
      topUsers: response.data.top_users
    };
  } catch (error) {
    console.error('Error fetching subject ID:', error);
    throw error;
  }
};