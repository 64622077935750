import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LibraryTabs from './LibraryTabs';
import { IoLibraryOutline } from "react-icons/io5";
import { UserContext } from '../../Context/AppContext';

function MyLibrary() {

  const { userSubject, userCounts } = useContext(UserContext);

  return (
    <div className='my-library'>
      <div className='my-library-header'>
        <IoLibraryOutline size={30} />
        <p>My Library</p>
      </div>
      <Routes>
        <Route path="/" element={<Navigate to="/my_library/resources" replace />} />
        <Route path="/:activeTab/*" element={<LibraryTabs userSubject={userSubject} userCounts={userCounts} />} />
      </Routes>
    </div>
  );
}

export default MyLibrary; 
