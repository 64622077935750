import React from 'react';
import { subjectPage } from '@linko/shared_utils';
import CustomLink from '../../Components/CustomLink';
const MySubjects = ({userSubject}) => {

    const myTags = userSubject?.filter(item => item.is_linked === false);
    const topSubject = userSubject?.filter(item => item.is_linked === true).sort((a, b) => b.learn_count - a.learn_count).slice(0, 20);

    return (
        <div className='your-subjects'>
            <div className='your-subjects-section hide-scrollbar'>
                {topSubject?.map((field) => (
                    <div key={field.id} className='subject-tag studied'>
                        <CustomLink
                            to={subjectPage(field.name, !field.is_linked)}
                        >
                            {field.name}
                        </CustomLink>
                    </div>
                ))}
            </div>
            <div className='your-subjects-section hide-scrollbar'>
                {myTags && myTags.map((tag) => (
                    <div key={tag.id} className='cus-label'>
                        <CustomLink
                            to={subjectPage(tag.name, !tag.is_linked)}
                        >
                            {tag.name}
                        </CustomLink>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MySubjects;
