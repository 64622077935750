import React, { useState, useEffect } from 'react';
import { subjectPage } from '@linko/shared_utils';
import CustomLink from '../../Components/CustomLink';

const SubjectOverview = ({userSubject}) => {
    const [myTags, setMyTags] = useState([]);
    const [topSubject, setTopSubject] = useState([]);
    const [showMyTags, setShowMyTags] = useState(false);
    const [showTopSubject, setShowTopSubject] = useState(false);

    useEffect(() => {
        if (userSubject && userSubject.length > 0) {
            const newMyTags = userSubject
                .filter(item => item.is_linked === false)
                .sort((a, b) => b.learn_count_unexpand - a.learn_count_unexpand);
            setMyTags(newMyTags);

            const newTopSubject = userSubject
                .filter(item => item.is_linked === true)
                .sort((a, b) => b.learn_count_unexpand - a.learn_count_unexpand)
                .slice(0, 20);
            setTopSubject(newTopSubject);
        }
    }, [userSubject]);

    return (
        <div className='subject-rel'>
        {topSubject && topSubject.length > 0 &&
        <>
            <div className='subject-rel-title'>My Subjects</div>
            <div className='subject-rel-list'>
                {topSubject?.filter(field => field.learn_count_unexpand > 0).slice(0, showTopSubject ? topSubject.length : 10).map((field, index) => (
                    <div key={index} className={`subject-tag ${field.learn_count_unexpand > 0 ? 'studied' : 'not-studied'}`}>
                        <CustomLink
                            to={subjectPage(field.name, false)}
                        >
                            {field.name}
                            {field.learn_count > 0 && ` · ${field.learn_count}`}
                        </CustomLink>
                    </div>
                ))}
                {topSubject?.filter(field => field.learn_count_unexpand > 0).length > 10 && ( 
                    showTopSubject? 
                        <button className='show-button' onClick={() => setShowTopSubject(false)}>Show Less</button>
                        :
                        <button className='show-button' onClick={() => setShowTopSubject(true)}>Show more</button>
                )}
            </div>
        </>
        }
        {myTags && myTags.length > 0 && 
        <>
            <div className='subject-rel-title'>My Tags</div>
            <div className='subject-rel-list my-tags'>
                {myTags.slice(0, showMyTags ? myTags.length : 10).map((tag, index) => (
                    <div key={index} className='my-tag' style={{color:'#F27215'}}>
                        <CustomLink
                            to={subjectPage(tag.name, true)}
                        >
                            {tag.name}
                            {tag.learn_count > 0 && ` · ${tag.learn_count}`}
                        </CustomLink>
                    </div>
                ))}
                {myTags.length > 10 && (
                    showMyTags? 
                        <button className='show-button' onClick={() => setShowMyTags(false)}>Show Less</button>
                        :
                        <button className='show-button' onClick={() => setShowMyTags(true)}>Show more</button>
                )}
            </div>
        </>
        }
        </div>
    );
};

export default SubjectOverview;
