import React from 'react';
import ReactMarkdown from 'react-markdown';
import { PiNotePencilBold } from "react-icons/pi";


const SlimNoteCard = ({ content, title }) => {
  // Function to truncate text to two lines
  const truncateText = (text, lines = 2) => {
    if (!text) return '';
    
    const words = text.split(' ');
    let result = '';
    let lineCount = 0;
    
    for (let word of words) {
      if ((result + word).length > 80 * lines) {
        if (lineCount < lines - 1) {
          result += '\n';
          lineCount++;
        } else {
          result += '...';
          break;
        }
      }
      result += word + ' ';
    }
    
    return result.trim();
  };

  return (
    <div className="slim-note-card">
      <div className="icon-container">
        <PiNotePencilBold size={20} color='#999' />
      </div>
      <div className="content-container">
        {title && <h4 className="resource-title">{title}</h4>}
        <div className="resource-content">
          <ReactMarkdown>{truncateText(content)}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default SlimNoteCard;